import { styled } from 'styled-components';

export const Shr = styled.hr`
  display: inline-block;
  width: 100%;
  max-height: 1px;
  margin: 0;
  padding: 0;
  border: none;
  border-bottom: 1px solid #eaeaea;
  border-radius: 50%;
`;
