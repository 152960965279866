import { type FC, forwardRef } from 'react';
import { EColors } from 'styles/style-variables/colors';

import { SVisuallyHidden } from 'components/styled/SVisuallyHidden';

import * as S from './SPageSection.styled';

interface IPropsSPageSection {
  children?: React.ReactNode;
  className?: string;
  sectionBackgroundColor?: EColors;
  tittleCeo?: string;
}

export const SPageSection = forwardRef<HTMLDivElement, IPropsSPageSection>(
  ({ children, className, sectionBackgroundColor, tittleCeo, ...props }, ref): JSX.Element => {
    return (
      <S.PageSection
        as="section"
        $backgroundColor={sectionBackgroundColor}
        className={className}
        ref={ref}
      >
        <SVisuallyHidden as="h2">{tittleCeo}</SVisuallyHidden>
        {children}
      </S.PageSection>
    );
  },
);
