import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { useInnerWidth } from 'hooks/useInnerWidth';
import { VerificationPanel } from 'pages/PageVerification/components/ui/VerificationPanel';
import { selectProfile } from 'store/profile-reducer/profile.selectors';
import { requestProfileDataThunkAction } from 'store/profile-reducer/profile.thunk-actions';
import { useAppDispatch } from 'store/store';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { EProfileTabName } from 'types/ui/ProfileTabs/profile-tab-name.type';

import { SPage } from 'components/styled/SPage';
import { Tabs } from 'components/ui/Tabs';
import { TabsSlider } from 'components/ui/TabsSlider';
import { getIsBasicVerificationStatus } from 'utils/common/get-is-basic-verification-status.util';
import { getIsFullVerificationStatus } from 'utils/common/get-is-full-verification-status.util';
import { getVerificationPanelText } from 'utils/common/get-verification-panel-text.util';
import { getVerificationStatusTag } from 'utils/common/get-verification-status-tag.util';
import { AppRoute } from 'utils/route/app-route';

import { UserNameCard } from './components/ui/UserNameCard';
import { profileTabItems } from './constants/profile-tab-items.const';
import { useActiveTabValue } from './hooks/useActiveTabValue';

import * as S from './PageProfile.styled';

export const PageProfile: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { firstname, lastname, verificationStatusCode } = useSelector(selectProfile);
  const activeTabValue = useActiveTabValue();
  const status = getVerificationStatusTag(verificationStatusCode);
  const { t } = useTranslation();
  status.statusText = t(`kyc.${status.statusText}`);
  const { innerWidth, innerWidthWithoutScrollBar } = useInnerWidth();

  const isDesktop = innerWidth >= parseInt(BreakPoint.TabletLow);
  const isBasicVerification = getIsBasicVerificationStatus(verificationStatusCode);
  const isFullVerification = getIsFullVerificationStatus(verificationStatusCode);
  const verificationPanelLabel = isBasicVerification
    ? t('kyc.labelForBasicVerified')
    : t('kyc.labelForNotVerified');
  const verificationInfo = getVerificationPanelText(isBasicVerification);
  const verificationPanelData = isBasicVerification ? [verificationInfo[1]] : verificationInfo;
  const fullName = `${firstname} ${lastname}`;

  const tabItems = profileTabItems.map((el) => {
    return {
      text: t(`profile.${el.text}`),
      value: el.value,
      icon: el.icon,
    };
  });

  const handleTabClick = (newTabValue: string) =>
    navigate(AppRoute.ProfileTab(newTabValue as EProfileTabName));

  useEffect(() => {
    void dispatch(requestProfileDataThunkAction());
  }, [dispatch]);

  return (
    <SPage pageTittle={t('profile.title')}>
      <UserNameCard verificationStatus={status} fullName={fullName} t={t} />
      {!isFullVerification && (
        <VerificationPanel
          label={verificationPanelLabel}
          hasLimitTag={isBasicVerification}
          data={verificationPanelData}
          t={t}
        />
      )}
      {isDesktop ? (
        <Tabs tabItems={tabItems} activeTabValue={activeTabValue} handleTabClick={handleTabClick} />
      ) : (
        <S.TabsSliderContainer $innerWidthWithoutScrollBar={innerWidthWithoutScrollBar}>
          <TabsSlider
            items={tabItems}
            activeTabValue={activeTabValue}
            handleTabClick={handleTabClick}
          />
        </S.TabsSliderContainer>
      )}
      <Outlet />
    </SPage>
  );
};
