import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import {
  applyPromocode,
  checkPromocodeAvailability,
} from 'store/api/cuverse-api/promocode/promocode.service';
import { IPromocodeError } from 'store/api/cuverse-api/promocode/types/coupon-error.interface';
import {
  IPromocodeCheckDataMapped,
  IPromocodeCheckResponse,
  IPromocodeResponse,
  IPromocodeResponseDataMapped,
} from 'store/api/cuverse-api/promocode/types/promocode-response.interface';
import { updateInvoiceStateAction } from 'store/invoice-reducer/invoice.reducer';

import { mapCouponCheckData, mapCouponData } from 'utils/mapper/mapper';
import { notifyError } from 'utils/notify/notify.utils';

import { EPromoCodeCategories } from './constants/promocode-categories';

interface ICheckPromo {
  category: EPromoCodeCategories;
  promoCode: string;
}

interface IApplyPromo {
  category: EPromoCodeCategories;
  purchaseId?: number;
  promoCode: string;
}

export const checkPromoAvailabilityThunkAction = createAsyncThunk<
  IPromocodeCheckDataMapped,
  ICheckPromo
>('promocode/checkPromoAvailability', async ({ category, promoCode }) => {
  return await checkPromocodeAvailability({
    coupon_type: category,
    coupon_code: promoCode,
  })
    .then((response: IPromocodeCheckResponse) => {
      return mapCouponCheckData(response.data);
    })
    .catch((error: AxiosError<IPromocodeError>) => {
      const errorMessages = error.response?.data.errors;
      const errorMessage = errorMessages?.coupon_code[0] as string;
      if (errorMessage) {
        notifyError(errorMessage);
      }

      throw error;
    });
});

export const applyPromoThunkAction = createAsyncThunk<IPromocodeResponseDataMapped, IApplyPromo>(
  'promocode/applyPromo',
  async ({ category, purchaseId = null, promoCode }, { dispatch }) => {
    return await applyPromocode({
      coupon_type: category,
      purchase_id: purchaseId,
      coupon_code: promoCode,
    })
      .then((response: IPromocodeResponse) => {
        const couponData = mapCouponData(response.data);

        const errors = response.data.errors;

        if (errors) {
          notifyError(Object.values(errors)[0]);
          throw new Error();
        }

        if (category === EPromoCodeCategories.Cart) {
          dispatch(updateInvoiceStateAction(couponData));
        }

        return couponData;
      })
      .catch((error: AxiosError<IPromocodeError>) => {
        const errorMessages = error.response?.data.errors;
        const errorMessage = errorMessages?.coupon_code[0] as string;
        if (errorMessage) {
          notifyError(errorMessage);
        }
        throw error;
      });
  },
);
