import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as IdentificationCardIcon } from 'assets/icons/verify-icons/basic-verification-success-icon.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/verify-icons/check-icon.svg';
import { useInnerWidth } from 'hooks/useInnerWidth';
import { verificationStatuses } from 'pages/PageVerification/constants/constants';
import { closePopupAction } from 'store/modals-reducer/modals.reducer';
import { selectIsWithdrawBtcPopupOpened } from 'store/modals-reducer/modals.selectors';
import { selectProfile } from 'store/profile-reducer/profile.selectors';
import { useAppDispatch } from 'store/store';
import { BreakPoint } from 'styles/style-variables/breakpoint';

import { getIsBasicVerificationStatus } from 'utils/common/get-is-basic-verification-status.util';
import { AppRoute } from 'utils/route/app-route';

import * as S from './VerificationInfo.styled';

interface IVerificationPanelDescription {
  text: string;
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  description: string;
  buttonText: string;
}

interface IProps {
  data: IVerificationPanelDescription[];
  isWithdrawalView?: boolean;
  isLimitWithdrawalPanel?: boolean;
}

export const VerificationInfo: React.FC<IProps> = ({
  data,
  isWithdrawalView,
  isLimitWithdrawalPanel,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isWithdrawPopupOpen = useSelector(selectIsWithdrawBtcPopupOpened);
  const { verificationStatusCode } = useSelector(selectProfile);
  const isBasicVerification = getIsBasicVerificationStatus(verificationStatusCode);
  const { innerWidth } = useInnerWidth();
  const { t } = useTranslation();

  const handleVerificationRedirect = () => {
    if (isWithdrawPopupOpen) {
      dispatch(closePopupAction());
    }
    navigate(AppRoute.Verification());
  };

  const getButtonVariant = (item: IVerificationPanelDescription) => {
    const isDesabledButton =
      !isBasicVerification && item.text === verificationStatuses.fullVerification.statusText;
    return isLimitWithdrawalPanel ? 'borderless' : isDesabledButton ? 'secondary' : 'primary';
  };

  const getIcon = (item: IVerificationPanelDescription) => {
    const isDesabledButton =
      !isBasicVerification && item.text === verificationStatuses.fullVerification.statusText;

    if (!isDesabledButton) {
      return <CheckIcon />;
    } else if (
      isDesabledButton &&
      innerWidth >= parseInt(BreakPoint.MobileTop) &&
      !isWithdrawalView
    ) {
      return <IdentificationCardIcon />;
    }
    return undefined;
  };

  const renderDescription = (item: IVerificationPanelDescription) => (
    <S.DescriptionContainer key={item.text} isWithdrawalView={isWithdrawalView}>
      {isLimitWithdrawalPanel ? (
        <S.Descirption>
          <div>
            <item.Icon />
          </div>
          <S.DescriptionText>{t('kyc.verificationLabelForWithdrawal')}</S.DescriptionText>
        </S.Descirption>
      ) : (
        <>
          <S.Status>
            <item.Icon />
            {t(`kyc.${item.text}`)}
          </S.Status>
          <S.Info>{t(`kyc.${item.description}`)}</S.Info>
        </>
      )}
      <S.VerificationButton
        type="button"
        variant={getButtonVariant(item)}
        text={t(`kyc.${item.buttonText}`)}
        icon={getIcon(item)}
        onClick={handleVerificationRedirect}
        $isFullWidth={isWithdrawalView || !isBasicVerification}
        isLimitWithdrawalPanel={isLimitWithdrawalPanel}
        disabled={
          !isBasicVerification && item.text === verificationStatuses.fullVerification.statusText
        }
      />
    </S.DescriptionContainer>
  );

  return (
    <S.VerificationInfoWrapper isWithdrawalView={isWithdrawalView}>
      {data.map(renderDescription)}
    </S.VerificationInfoWrapper>
  );
};
