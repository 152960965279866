import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TFetchStatus } from 'types/api/fetch-status.type';

import {
  fetchInvoicePdfLinkAction,
  fetchInvoicePdfLinksAction,
} from './Invoice-pdf-links.thunk-action';

export interface IInvoicePdfLinksState {
  invoicePdfLinksData: string[];
  invoicePdfLinkData: string;
  lastSelectedOrderNumber?: number;
  status: TFetchStatus;
  error: string | null;
}

export const initialState: IInvoicePdfLinksState = {
  invoicePdfLinksData: [],
  invoicePdfLinkData: '',
  status: 'initial',
  error: null,
};

const invoicePdfLinksSlice = createSlice({
  name: 'download/invoice',
  initialState,
  reducers: {
    clearInvoicePdfLinkDataAction: (state) => {
      state.invoicePdfLinkData = '';
    },
    clearInvoicePdfLinksDataAction: (state) => {
      state.invoicePdfLinksData = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInvoicePdfLinksAction.pending, (state) => {
        state.status = 'pending';
        state.error = null;
      })
      .addCase(
        fetchInvoicePdfLinksAction.fulfilled,
        (state, { payload }: PayloadAction<string>) => {
          state.status = 'fulfilled';
          state.invoicePdfLinksData.push(payload);
        },
      )
      .addCase(fetchInvoicePdfLinksAction.rejected, (state, error) => {
        state.status = 'rejected';
        state.error = JSON.stringify(error);
      });
    builder
      .addCase(fetchInvoicePdfLinkAction.pending, (state) => {
        state.status = 'pending';
        state.error = null;
      })
      .addCase(fetchInvoicePdfLinkAction.fulfilled, (state, { payload }: PayloadAction<string>) => {
        state.status = 'fulfilled';
        state.invoicePdfLinkData = payload;
      })
      .addCase(fetchInvoicePdfLinkAction.rejected, (state, error) => {
        state.status = 'rejected';
        state.error = JSON.stringify(error);
      });
  },
});

export const invoicePdfLinksReducer = invoicePdfLinksSlice.reducer;
export const { clearInvoicePdfLinksDataAction, clearInvoicePdfLinkDataAction } =
  invoicePdfLinksSlice.actions;
