import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { FontFamily } from 'styles/style-variables/font-family';

export const Option = styled.dl`
  display: flex;
  flex-direction: column;
  width: max-content;
  width: 100%;

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    align-items: end;
  }
`;

export const PaymentTitleWrapper = styled.div`
  margin-bottom: 10px;

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    align-self: start;
  }
`;

export const Title = styled.dt`
  font-family: ${FontFamily.Montserrat};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #646464;
`;

export const BtcTransferWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  margin-bottom: 16px;
  padding: 12px 12px 12px 16px;
  border-radius: 24px;
  background: #e5eaf1;

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    align-self: start;
    width: 262px;
    margin-bottom: 0;
  }
`;

export const BtcTitle = styled.dt`
  font-family: ${FontFamily.Montserrat};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  color: #272727;
`;
