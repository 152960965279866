import styled from 'styled-components';
import { EColors } from 'styles/style-variables/colors';
import { FontFamily } from 'styles/style-variables/font-family';

export const SText = styled.span`
  font-family: ${FontFamily.Montserrat};
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  color: ${EColors.Gray1Text};
`;
