import { createSelector } from '@reduxjs/toolkit';
import { TRootState } from 'store/store';

import { IPromocodeState } from './promocode.reducer';

export const getPromocodeState = (state: TRootState): IPromocodeState => state.promocodeReducer;

export const selectPromoData = createSelector(
  [getPromocodeState],
  (promocodeState: IPromocodeState) => promocodeState.couponData,
);

export const selectPromoError = createSelector(
  [getPromocodeState],
  (promocodeState: IPromocodeState) => promocodeState.promoError,
);

export const selectPromoStatus = createSelector(
  [getPromocodeState],
  (promocodeState: IPromocodeState) => promocodeState.promoStatus,
);

export const selectCategory = createSelector(
  [getPromocodeState],
  (promocodeState: IPromocodeState) => promocodeState.category,
);

export const selectDiscountType = createSelector(
  [getPromocodeState],
  (promocodeState: IPromocodeState) => promocodeState.discountType,
);

export const selectDiscountValue = createSelector(
  [getPromocodeState],
  (promocodeState: IPromocodeState) => promocodeState.discount,
);

export const selectCouponDiscount = createSelector(
  [getPromocodeState],
  (promocodeState: IPromocodeState) => promocodeState.couponData.discount,
);

export const selectPromocodeId = createSelector(
  [getPromocodeState],
  (promocodeState: IPromocodeState) => promocodeState.promocodeId,
);

export const selectPromoCheckStatus = createSelector(
  [getPromocodeState],
  (promocodeState: IPromocodeState) => promocodeState.promoCheckStatus,
);
