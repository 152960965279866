import React from 'react';
import { ReactComponent as UserIcon } from 'assets/icons/profile/user-icon.svg';
import { VerificationStatus } from 'pages/PageProfile/components/ui/VerificationStatus';
import { verificationStatuses } from 'pages/PageVerification/constants/constants';

import { SPageSection } from 'components/styled/SPageSection';

import { IUserNameCardProps } from './types/user-name-card-props.interface';

import * as S from './UserNameCard.styled';

export const UserNameCard: React.FC<IUserNameCardProps> = ({ fullName, verificationStatus, t }) => {
  return (
    <S.UserNameCard>
      <SPageSection className={'section-wrapper'} tittleCeo="user name card">
        <S.UserInfoCard>
          <S.IconWrapper>
            <UserIcon />
            <S.UserName>{fullName}</S.UserName>
          </S.IconWrapper>
          <VerificationStatus
            label={t('profile.statusLabel')}
            statusText={verificationStatus.statusText}
            Icon={verificationStatus.icon}
            isVerified={
              verificationStatus.statusText === verificationStatuses.basicVerification.statusText ||
              verificationStatus.statusText === verificationStatuses.fullVerification.statusText
            }
          />
        </S.UserInfoCard>
      </SPageSection>
    </S.UserNameCard>
  );
};
