import { createSelector } from '@reduxjs/toolkit';
import { TRootState } from 'store/store';

import { IInvoiceState } from './invoice.reducer';

export const getInvoiceState = (state: TRootState): IInvoiceState => state.invoiceReducer;

export const selectInvoiceData = createSelector(
  [getInvoiceState],
  (invoiceState: IInvoiceState) => invoiceState.invoiceData,
);

export const selectInvoiceStatus = createSelector(
  [getInvoiceState],
  (invoiceState: IInvoiceState) => invoiceState.invoiceFetchStatus,
);

export const selectInvoiceError = createSelector(
  [getInvoiceState],
  (invoiceState: IInvoiceState) => invoiceState.invoiceError,
);
