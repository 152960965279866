import styled, { css } from 'styled-components';
import { shake } from 'styles/keyframes/shake';
import { AnimationDuration } from 'styles/style-variables/animation-duration';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { EColors } from 'styles/style-variables/colors';

import { SText } from 'components/styled/SText';
import { Button } from 'components/ui/Button';
import { Input } from 'components/ui/Input';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0 24px 24px;

  @media (max-width: ${BreakPoint.MobileTop}) {
    padding-right: 16px;
    padding-left: 16px;
  }
`;

export const InstructionText = styled(SText)`
  font-size: 14px;
  line-height: 20px;
`;

export const Form = styled.form<{ $isFailed: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 4px;

  ${({ $isFailed }) => {
    if ($isFailed) {
      return css`
        animation: ${shake} ${AnimationDuration.Shake} ease;
      `;
    }
  }};
`;

export const InputLabel = styled.label`
  display: flex;
  flex-direction: column;
  gap: 4px;
  min-height: 112px;

  @media (max-width: ${BreakPoint.MobileTop}) {
    min-height: 104px;
  }
`;

export const LabelText = styled(SText)`
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;

  @media (max-width: ${BreakPoint.MobileTop}) {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.5px;
  }

  &::after {
    content: '*';
    margin-left: 4px;
    font-weight: 900;
    color: #ec494f;
  }
`;

export const InputWrapper = styled.div`
  position: relative;
`;

export const OtpInput = styled(Input)`
  & input {
    padding-right: 170px;

    & + svg {
      right: 150px;
    }
  }

  @media (max-width: ${BreakPoint.MobileTop}) {

    & input {
      height: 42px;
      padding: 11px 170px 11px 15px;
    }
  }
`;

export const ResendCodeButton = styled(Button)`
  position: absolute;
  top: 50%;
  right: 5px;
  min-width: 140px;
  transform: translateY(-50%);
  font-variant-numeric: tabular-nums;
`;

export const ErrorText = styled(SText)`
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color: #ec494f;
`;

export const SubmitButton = styled(Button)`
  padding: 12px 16px;
  border-radius: 24px;
`;

export const CheckSpamNote = styled(InstructionText)`
  color: #646464;
`;
