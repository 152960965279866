import styled, { css } from 'styled-components';
import { shake } from 'styles/keyframes/shake';
import { AnimationDuration } from 'styles/style-variables/animation-duration';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { EColors } from 'styles/style-variables/colors';
import { FontFamily } from 'styles/style-variables/font-family';

import { SText } from 'components/styled/SText';
import { Button } from 'components/ui/Button';

export const Form = styled.form<{ $isFailed: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;

  ${({ $isFailed }) => {
    if ($isFailed) {
      return css`
        animation: ${shake} ${AnimationDuration.Shake} ease;
      `;
    }
  }};

  @media (max-width: ${BreakPoint.MobileTop}) {
    align-items: stretch;
  }
`;

export const FormSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;

  @media (max-width: ${BreakPoint.MobileTop}) {
    gap: 16px;
  }
`;

export const FormSectionTitle = styled.h2`
  font-family: ${FontFamily.Montserrat};
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: #272727;

  @media (max-width: ${BreakPoint.MobileTop}) {
    font-size: 14px;
    line-height: 18px;
  }
`;

export const PersonalFieldsWrapper = styled.div`
  display: grid;
  gap: 0 24px;
  grid-template-columns: 1fr 1fr;

  @media (max-width: ${BreakPoint.TabletTop}) {
    gap: 0 20px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    grid-template-columns: 1fr;
  }
`;

export const AddressFieldsWrapper = styled.div`
  display: grid;
  gap: 0 24px;
  grid-template-columns: 1fr;
`;

export const HomeAddressWrapper = styled.div`
  display: grid;
  gap: 0 24px;
  grid-template-columns: 1fr 1fr 1fr 140px;

  @media (max-width: ${BreakPoint.TabletTop}) {
    gap: 0 20px;
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    grid-template-columns: 1fr;
  }
`;

export const InputLabel = styled.label`
  display: flex;
  flex-direction: column;
  gap: 4px;
  min-height: 90px;

  @media (max-width: ${BreakPoint.MobileTop}) {
    min-height: 76px;
  }
`;

export const DatePickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  grid-column: span 2;
  max-width: 220px;
  min-height: 90px;

  @media (max-width: ${BreakPoint.MobileTop}) {
    grid-column: span 1;
    max-width: 170px;
    min-height: 76px;
  }
`;

export const DatePickerLabel = styled(InputLabel)`
  min-height: unset;

  @media (max-width: ${BreakPoint.MobileTop}) {
    min-height: unset;
  }
`;

export const EmailLabel = styled(InputLabel)`
  @media (max-width: ${BreakPoint.TabletTop}) {
    grid-column: span 2;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    grid-column: span 1;
  }
`;

export const PostalCodeLabel = styled(InputLabel)`
  max-width: 140px;
`;

export const MailingAddressLabel = styled(InputLabel)`
  position: relative;
  padding-bottom: 24px;

  & > span:last-child {
    position: absolute;
    bottom: 4px;
    left: 0;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    min-height: 140px;
    padding-bottom: 0;
  }
`;

export const TimeZoneSelectLabel = styled(InputLabel)`
  max-width: 348px;
`;

export const LabelText = styled(SText)`
  padding-bottom: 4px;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #646464;

  @media (max-width: ${BreakPoint.MobileTop}) {
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.3px;
  }
`;

export const ErrorText = styled(SText)`
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color: #ec494f;
`;

export const SubmitButton = styled(Button)`
  padding: 12px 16px;
  border-radius: 24px;
`;
