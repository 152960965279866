import { EPurchaseStep } from 'store/api/cuverse-api/purchase/types/purchase-step.type';
import styled, { css } from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { FontFamily } from 'styles/style-variables/font-family';

export const PaymentDetailsWrapper = styled.div`
  grid-area: paymentDetails;
  font-family: ${FontFamily.Montserrat};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  font-variant-numeric: lining-nums tabular-nums;
`;

export const PaymentData = styled.dl<{ $purchaseStep: EPurchaseStep }>`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    ${({ $purchaseStep }) => {
      if ($purchaseStep === EPurchaseStep.Buying) {
        return css`
          flex-direction: column;
        `;
      }

      return css`
        flex-direction: row;
      `;
    }}
  }
`;

export const Title = styled.dt`
  color: #646464;
`;

export const DescriptionValue = styled.dd`
  justify-self: end;
  font-size: 14px;
  line-height: 18px;
  color: #272727;
`;

export const FeesAndTaxesWrapper = styled.div<{ $purchaseStep: EPurchaseStep }>`
  display: flex;
  justify-content: space-between;

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    ${({ $purchaseStep }) => {
      if ($purchaseStep === EPurchaseStep.Buying) {
        return css`
          flex-direction: row;
        `;
      }
      return css`
        flex-direction: column;
      `;
    }}
    margin-right: auto;
  }
`;

export const TittleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const MinersTotalWrapper = styled.div<{ $purchaseStep: EPurchaseStep }>`
  display: flex;
  justify-content: space-between;

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    ${({ $purchaseStep }) => {
      if ($purchaseStep === EPurchaseStep.Buying) {
        return css`
          flex-direction: row;
        `;
      }

      return css`
        flex-direction: column;
      `;
    }}
  }
`;
