import type { FC } from 'react';
import React from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { DownloadPdfText } from 'pages/PageProfile/components/logic/DownloadPdfText';
import { IOrdersHistoryDataMapped } from 'store/api/cuverse-api/profile/types/orders-history-response.interface';
import { clearInvoicePdfLinksDataAction } from 'store/Invoice-pdf-links-reducer/Invoice-pdf-links.reducer';
import {
  selectInvoicePdfLink,
  selectInvoicePdfLinkStatus,
} from 'store/Invoice-pdf-links-reducer/Invoice-pdf-links.selectors';
import { fetchInvoicePdfLinkAction } from 'store/Invoice-pdf-links-reducer/Invoice-pdf-links.thunk-action';
import { useAppDispatch } from 'store/store';

import { formatUsdOutput } from 'utils/formatters/format-usd-output.util';

import * as S from './OrdersHistoryDesktop.styled';

interface IOrdersHistoryDesktopProps {
  t: (key: string) => string;
  ordersHistory: IOrdersHistoryDataMapped[];
}

export const OrdersHistoryDesktop: FC<IOrdersHistoryDesktopProps> = ({
  t,
  ordersHistory,
}): JSX.Element => {
  return (
    <S.TableWrapper>
      <S.Table as="table">
        <thead>
          <tr>
            <th>
              <S.HeadCell>{t('profile.date')}</S.HeadCell>
            </th>
            <th>
              <S.HeadCell>{t('profile.orderNumber')}</S.HeadCell>
            </th>
            <th>
              <S.HeadCell>{t('profile.order')}</S.HeadCell>
            </th>
            <th>
              <S.HeadCell>{t('profile.total')}</S.HeadCell>
            </th>
            <th>
              <S.HeadCell>{t('profile.invoice')}</S.HeadCell>
            </th>
          </tr>
        </thead>
        <tbody>
          {ordersHistory.map(({ date, orderNumber, order, total, invoiceFile }) => (
            <React.Fragment key={orderNumber}>
              <tr>
                <td>
                  <S.ValueCell>{dayjs.unix(date).utc().format('DD.MM.YYYY')}</S.ValueCell>
                </td>
                <td>
                  <S.ValueCell>{orderNumber}</S.ValueCell>
                </td>
                <td>
                  <S.ValueCell>{order}</S.ValueCell>
                </td>
                <td>
                  <S.ValueCell>${formatUsdOutput(total)}</S.ValueCell>
                </td>
                <td>
                  <S.ValueCell>
                    <DownloadPdfText orderNumber={orderNumber} invoiceFile={invoiceFile} t={t} />
                  </S.ValueCell>
                </td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </S.Table>
    </S.TableWrapper>
  );
};
