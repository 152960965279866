import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { formatUsdOutput } from 'utils/formatters/format-usd-output.util';

import * as S from './Discount.styled';

interface IPropsTotalValue {
  discount: number;
  sumUsd: number;
}

export const Discount: FC<IPropsTotalValue> = ({ discount, sumUsd }): JSX.Element => {
  const discontUsd = (discount / 100) * sumUsd;
  const { t } = useTranslation();

  return (
    <S.DiscountWrapper>
      <S.Title>{t('additionalTerms.discountTitle')}</S.Title>
      <S.ValuesWrapper>
        <S.Value>${formatUsdOutput(discontUsd)}</S.Value>
        <S.Value>({discount}%)</S.Value>
      </S.ValuesWrapper>
    </S.DiscountWrapper>
  );
};
