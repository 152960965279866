import { ReactComponent as PersonalInfoIcon } from 'assets/icons/button-icons/contacts.svg';
import { ReactComponent as SecurityIcon } from 'assets/icons/button-icons/shield.svg';
import { ReactComponent as ShoppingCartIcon } from 'assets/icons/button-icons/shopping-cart-simple.svg';
import { ReactComponent as WalletIcon } from 'assets/icons/button-icons/wallet.svg';
import { IProfileTabItem } from 'pages/PageProfile/types/profile-tab-item.interface';
import { EProfileTabName } from 'types/ui/ProfileTabs/profile-tab-name.type';

export const profileTabItems: IProfileTabItem[] = [
  {
    text: 'personalInformationTitle',
    value: EProfileTabName.Personal,
    icon: <PersonalInfoIcon />,
  },
  {
    text: 'orders',
    value: EProfileTabName.Orders,
    icon: <ShoppingCartIcon />,
  },
  {
    text: 'wallet',
    value: EProfileTabName.Wallet,
    icon: <WalletIcon />,
  },
  {
    text: 'security',
    value: EProfileTabName.Security,
    icon: <SecurityIcon />,
  },
];
