import { styled } from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { EColors } from 'styles/style-variables/colors';
import { FontFamily } from 'styles/style-variables/font-family';

import { SText } from 'components/styled/SText';

export const TotalAmountWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    margin-right: auto;
  }
`;

export const Title = styled(SText)`
  font-size: 12px;
  font-style: normal;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: ${EColors.Gray2};
`;

export const ValuesWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Value = styled(SText)`
  font-size: 18px;
  font-style: normal;
  line-height: 24px;
  white-space: nowrap;
  font-variant-numeric: lining-nums tabular-nums;
`;

export const BtcValue = styled(Value)`
  font-size: 14px;
  line-height: 18px;
`;
