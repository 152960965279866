import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { EColors } from 'styles/style-variables/colors';

export const SStyledTabContentWrapper = styled.div`
  padding: 24px;
  border-radius: 24px;
  background-color: ${EColors.White};

  @media (max-width: ${BreakPoint.LaptopTop}) {
    border-radius: 16px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    padding: 16px;
  }
`;
