import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { EColors } from 'styles/style-variables/colors';
import { FontFamily } from 'styles/style-variables/font-family';

import { SText } from 'components/styled/SText';
import { Button } from 'components/ui/Button';

export const InvoiceStatus = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 24px;
  padding: 32px;
  border-radius: 24px;
  background-color: ${EColors.White};

  @media (min-width: ${BreakPoint.LaptopLow}) and (max-width: ${BreakPoint.LaptopTop}) {
    border-radius: 16px;
  }

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    width: 100%;
    border-radius: 16px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    gap: 16px;
    width: 100%;
    padding: 16px;
    border-radius: 8px;
  }
`;

export const TittleBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Title = styled(SText)`
  font-size: 36px;
  font-style: normal;
  line-height: 48px;

  @media (max-width: ${BreakPoint.MobileTop}) {
    margin-bottom: 8px;
    font-size: 18px;
    line-height: 24px;
  }
`;

export const LaunchNotion = styled(SText)`
  font-size: 14px;
  font-style: normal;
  line-height: 18px;
  font-variant-numeric: lining-nums tabular-nums;

  @media (max-width: ${BreakPoint.MobileTop}) {
    font-size: 12px;
  }
`;

export const LaunchDate = styled(LaunchNotion)`
  line-height: 20px;

  @media (max-width: ${BreakPoint.MobileTop}) {
    line-height: 16px;
  }
`;

export const UptimeBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  border-radius: 16px;
  background: ${EColors.BlueGrayBack};

  @media (max-width: ${BreakPoint.MobileTop}) {
    padding: 16px;
  }
`;

export const UptimeSwitcherWrapper = styled.div`
  display: flex;
  gap: 16px;

  @media (max-width: ${BreakPoint.MobileTop}) {
    flex-direction: column;
    align-items: center;

    & > div {
      justify-content: center;
      width: 100%;
    }

    & > button {
      width: 100%;
    }
  }
`;

export const WarningBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 16px;
  border-radius: 16px;
  background: ${EColors.BlueGrayBack};

  & svg {
    width: 40px;
    min-width: 40px;
    height: max-content;
  }

  @media (max-width: ${BreakPoint.TabletTop}) {

    & svg {
      width: 40px;
      min-width: 40px;
    }
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    align-items: start;
    gap: 8px;

    & svg {
      width: 16px;
      min-width: 16px;
    }
  }
`;

export const WarningText = styled(SText)`
  font-size: 14px;
  font-style: normal;
  line-height: 20px;

  @media (max-width: ${BreakPoint.MobileTop}) {
    font-size: 12px;
    line-height: 18px;
  }
`;

export const WarningSpan = styled(WarningText)`
  font-weight: 700;
  line-height: 18px;

  @media (max-width: ${BreakPoint.MobileTop}) {
    line-height: 16px;
  }
`;

export const ElectricityBalanceBlock = styled.dl`
  display: flex;
  align-items: center;
  gap: 16px;

  @media (max-width: ${BreakPoint.MobileTop}) {
    flex-wrap: wrap;
    gap: 0;
    margin-top: 8px;
  }
`;

export const BalanceTitle = styled.dt`
  max-width: 156px;
  font-family: ${FontFamily.Montserrat};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: ${EColors.Gray2};

  @media (max-width: ${BreakPoint.MobileTop}) {
    max-width: 100%;
    margin-bottom: 8px;
    font-size: 10px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0.3px;
  }
`;

export const BalanceValue = styled.dd`
  display: flex;
  align-items: baseline;
  gap: 12px;

  @media (max-width: ${BreakPoint.MobileTop}) {
    gap: 8px;
    width: 100%;
    margin-bottom: 16px;
  }
`;

export const BalanceUsd = styled(SText)`
  font-size: 24px;
  font-style: normal;
  line-height: 32px;
  color: ${EColors.Gray1Text};
  font-variant-numeric: lining-nums tabular-nums;

  @media (max-width: ${BreakPoint.MobileTop}) {
    font-size: 18px;
    line-height: 24px;
  }
`;

export const BalanceDays = styled(SText)`
  font-size: 14px;
  font-style: normal;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${EColors.Gray2};
  font-variant-numeric: lining-nums tabular-nums;

  @media (max-width: ${BreakPoint.MobileTop}) {
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.3px;
  }
`;

export const ReplenishButton = styled(Button)`
  gap: 8px;
  margin-left: auto;
  padding: 12px 16px;
  border-radius: 24px;

  @media (max-width: ${BreakPoint.MobileTop}) {
    width: 100%;
  }
`;
