import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { EColors } from 'styles/style-variables/colors';

import { SText } from 'components/styled/SText';

export const UserNameCard = styled.div`
  .section-wrapper {
    position: relative;
    z-index: 1;
    margin-top: -1.5rem;
    margin-bottom: 1rem;

    &::before {
      content: '';
      position: absolute;
      top: -50%;
      left: -24px;
      z-index: -1;
      width: 100%;
      height: 100%;
      background-color: ${EColors.Blue2NewBrand};

      @media (max-width: ${BreakPoint.TabletTop}) {
        left: -16px;
      }
    }

    &::after {
      content: '';
      position: absolute;
      top: -50%;
      right: -24px;
      z-index: -1;
      width: 100%;
      height: 100%;
      background-color: ${EColors.Blue2NewBrand};

      @media (max-width: ${BreakPoint.TabletTop}) {
        right: -16px;
      }
    }
  }
`;

export const UserInfoCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  padding: 24px;
  border-radius: 24px;
  background-color: #fff;

  @media (max-width: ${BreakPoint.TabletTop}) {
    justify-content: space-between;
    padding: 16px;
    border-radius: 16px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  & svg {
    flex-shrink: 0;
    width: 64px;
    height: 64px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {

    & svg {
      width: 48px;
      height: 48px;
    }
  }
`;

export const UserName = styled(SText)`
  width: min-content;
  overflow: auto;
  font-size: 18px;
  font-weight: 800;
  line-height: 24px;
  word-wrap: break-word;

  @media (max-width: ${BreakPoint.LaptopTop}) {
    font-size: 18px;
    line-height: 24px;
  }

  @media (max-width: ${BreakPoint.TabletTop}) {
    width: 100%;
  }
`;
