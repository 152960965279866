import styled, { css } from 'styled-components';
import { shake } from 'styles/keyframes/shake';
import { AnimationDuration } from 'styles/style-variables/animation-duration';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { EColors } from 'styles/style-variables/colors';
import { FontFamily } from 'styles/style-variables/font-family';

import { SText } from 'components/styled/SText';
import { Button } from 'components/ui/Button';
import { Input } from 'components/ui/Input';

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  border-radius: 12px;
  background-color: ${EColors.BlueGrayBack};

  @media (max-width: ${BreakPoint.MobileTop}) {
    padding: 0;
    background-color: transparent;
  }
`;

export const Currency = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  & svg {
    flex-shrink: 0;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {

    & svg {
      width: 16px;
      height: 16px;
    }
  }
`;

export const CurrencyName = styled(SText)`
  font-size: 18px;
  line-height: 24px;

  @media (max-width: ${BreakPoint.MobileTop}) {
    font-size: 14px;
    line-height: 18px;
  }
`;

export const WalletAddress = styled.div<{ $isFailed: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 8px;

  ${({ $isFailed }) => {
    if ($isFailed) {
      return css`
        animation: ${shake} ${AnimationDuration.Shake} ease;
      `;
    }
  }};

  @media (max-width: ${BreakPoint.MobileTop}) {
    gap: 10px;
  }
`;

export const InputLabel = styled.label`
  font-family: ${FontFamily.Montserrat};
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: ${EColors.Gray2};

  @media (max-width: ${BreakPoint.MobileTop}) {
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.3px;
  }
`;

export const AddressWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  @media (max-width: ${BreakPoint.TabletTop}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const WalletAddressInput = styled(Input)`
  & input:read-only,
  & input:read-only:focus {
    padding-right: 40px;
    border-color: transparent;
    background-color: ${EColors.Gray5};
    box-shadow: none;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {

    & input {
      font-size: 10px;
      line-height: 14px;
      letter-spacing: 0.2px;
    }
  }
`;

export const CopyButton = styled(Button)`
  position: absolute;
  top: 50%;
  right: 0;
  padding: 12px;
  transform: translateY(-50%);

  @media (max-width: ${BreakPoint.MobileTop}) {
    padding: 8px 12px;
  }
`;

export const ErrorText = styled(SText)`
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color: ${EColors.Red1};
`;

export const NotConfirmedBanner = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-start;
  gap: 12px;
  padding: 12px 24px;
  border-radius: 48px;
  background-color: ${EColors.BlueGrayBackDark};

  @media (max-width: ${BreakPoint.MobileTop}) {
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    gap: 8px;
    padding: 12px 16px;
    border-radius: 16px;
  }
`;

export const NotConfirmedNote = styled(SText)`
  font-size: 14px;
  line-height: 20px;

  @media (max-width: ${BreakPoint.MobileTop}) {
    font-size: 12px;
    line-height: 18px;
  }
`;

export const EditButton = styled(Button)`
  padding: 11px 15px;
  border-radius: 24px;
  white-space: nowrap;

  @media (max-width: ${BreakPoint.TabletTop}) {
    padding: 6px 11px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    padding: 0;
  }
`;
