import { PaymentAgreementForm } from 'pages/PagePurchase/components/ui/PaymentAgreementForm';
import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';

export const PromoCodeAreaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    flex-direction: row;
    align-items: center;
    gap: 24px;
  }
`;

export const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    flex-direction: row;
    align-items: end;
  }
`;

export const AgreementForm = styled(PaymentAgreementForm)`
  span {
    @media (max-width: ${BreakPoint.TabletTop}) {
      flex-direction: row;
      gap: 4px;
    }

    @media (max-width: ${BreakPoint.MobileTop}) {
      flex-direction: column;
    }
  }
`;
