import { EPurchaseStep } from 'store/api/cuverse-api/purchase/types/purchase-step.type';
import styled, { css } from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { EColors } from 'styles/style-variables/colors';

export const SPaymentWrapper = styled.div<{ $purchaseStep: EPurchaseStep }>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-width: 352px;
  padding: 24px;
  border-radius: 24px;
  background-color: ${EColors.White};

  @media (min-width: ${BreakPoint.LaptopLow}) and (max-width: ${BreakPoint.LaptopTop}) {
    min-width: 310px;
    border-radius: 16px;
  }

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    border-radius: 16px;

    ${({ $purchaseStep }) => {
      if ($purchaseStep === EPurchaseStep.PaidSuccess) {
        return css`
          & > :nth-child(4) {
            min-width: 100%;
            margin-right: 0;
          }
        `;
      }
    }}
    & > :nth-child(3) {
      position: absolute;
      right: 40px;
      width: auto;
    }
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    width: 100%;
    min-width: 100%;
    padding: 16px;
    border-radius: 8px;
  }
`;
