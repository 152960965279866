import { styled } from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { EColors } from 'styles/style-variables/colors';

import { SText } from 'components/styled/SText';
import { Button } from 'components/ui/Button';

export const LimitedUptimeHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  @media (max-width: ${BreakPoint.MobileTop}) {
    flex-direction: column;
  }
`;

export const HowItWorksButton = styled(Button)`
  color: ${EColors.White};

  &:active,
  &:focus,
  &:hover {
    color: ${EColors.White};
  }
`;
