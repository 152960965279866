import { createSlice } from '@reduxjs/toolkit';
import { IMinerDataMapped } from 'store/api/cuverse-api/purchase/types/purchase-active-miners.interface';
import { EPurchaseStep } from 'store/api/cuverse-api/purchase/types/purchase-step.type';
import { TFetchStatus } from 'types/api/fetch-status.type';

import { fetchActiveMinersThunkAction } from './purchase.thunk-actions';

export interface IPurchaseState {
  purchaseStep: EPurchaseStep;
  activeMiners: IMinerDataMapped[];
  minersFetchStatus: TFetchStatus;
  minersError: string | null;
}

const initialState: IPurchaseState = {
  purchaseStep: EPurchaseStep.Buying,
  activeMiners: [],
  minersFetchStatus: 'initial',
  minersError: null,
};

const purchaseSlice = createSlice({
  name: 'purchase',
  initialState,
  reducers: {
    setPurchaseStepAction: (state, { payload }: { payload: EPurchaseStep }) => {
      state.purchaseStep = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchActiveMinersThunkAction.pending, (state) => {
        state.minersFetchStatus = 'pending';
      })
      .addCase(fetchActiveMinersThunkAction.fulfilled, (state, action) => {
        state.minersFetchStatus = 'fulfilled';
        state.activeMiners = action.payload;
      })
      .addCase(fetchActiveMinersThunkAction.rejected, (state, error) => {
        state.minersFetchStatus = 'rejected';
        state.minersError = JSON.stringify(error);
      });
  },
});

export const { setPurchaseStepAction } = purchaseSlice.actions;
export const purchaseReducer = purchaseSlice.reducer;
