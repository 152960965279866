import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { FallbackAlphaPoPaymentErrorMessage } from 'constants/notification-messages/fallback-alpha-po-payment-error-message';
import { FallbackPaymentMessages } from 'constants/notification-messages/fallback-payment-messages';
import {
  requestAlphaPoPayment,
  requestCardPayment,
  requestPayment,
} from 'store/api/cuverse-api/payment/payment.service';
import {
  ICardPaymentMessageMapped,
  IPaymentResponseData,
  TAlphaPoMessage,
} from 'store/api/cuverse-api/payment/types/payment-response.interface';
import { EPurchaseStep } from 'store/api/cuverse-api/purchase/types/purchase-step.type';
import { fetchInvoicePdfLinksAction } from 'store/Invoice-pdf-links-reducer/Invoice-pdf-links.thunk-action';
import { setPurchaseStepAction } from 'store/purchase-reducer/purchase.reducer';

import { mapCardPayment } from 'utils/mapper/mapper';
import { notifyError, notifySuccess } from 'utils/notify/notify.utils';

import { EOperationType, EPaymentMethod } from './payment.reducer';

export const fetchPaymentThunkAction = createAsyncThunk<
  IPaymentResponseData | TAlphaPoMessage | ICardPaymentMessageMapped,
  {
    id: number;
    paymentMethod: EPaymentMethod;
    operationType: EOperationType;
  }
>('payment/fetch', async ({ id, paymentMethod, operationType }, { dispatch, rejectWithValue }) => {
  let response;
  try {
    switch (paymentMethod) {
      case EPaymentMethod.BtcBalance: {
        response = await requestPayment({
          purchase_id: id,
          payment_method: paymentMethod,
          operation_type: operationType,
          language: 'en',
        });
        dispatch(setPurchaseStepAction(EPurchaseStep.PaidSuccess));
        notifySuccess(FallbackPaymentMessages.SuccessfullyPayment);
        const invoices = response.message.data?.map((invoice) => invoice.invoice_id);

        if (invoices && invoices[0]) {
          invoices.forEach((invoice_id) => dispatch(fetchInvoicePdfLinksAction(invoice_id)));
        }
        return response.message;
      }
      case EPaymentMethod.AlphaPo: {
        response = await requestAlphaPoPayment({
          purchase_id: id,
          payment_method: paymentMethod,
          operation_type: operationType,
          language: 'en',
        });
        if (!response?.message) {
          throw new Error(FallbackAlphaPoPaymentErrorMessage.FailedToGetPaymentLink);
        }
        return response.message;
      }
      case EPaymentMethod.Mercuryo: {
        response = await requestCardPayment({
          purchase_id: id,
          payment_method: paymentMethod,
          operation_type: operationType,
          language: 'en',
        });
        if (!response?.message) {
          throw new Error(FallbackAlphaPoPaymentErrorMessage.FailedToGetPaymentLink);
        }
        return mapCardPayment(response.message);
      }
      default:
        throw new Error('Invalid payment method');
    }
  } catch (error: AxiosError | unknown) {
    if (error instanceof AxiosError) {
      if (error.response?.data.message) {
        notifyError(error.response?.data.message as string);
        return rejectWithValue(error);
      }
      notifyError(error.message);
      return rejectWithValue(error);
    } else {
      notifyError(`${error}`);
    }
    return rejectWithValue(error);
  }
});
