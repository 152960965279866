import { styled } from 'styled-components';
import { EColors } from 'styles/style-variables/colors';

import { SText } from 'components/styled/SText';

export const SwitcherWrapper = styled.div<{ $wrapperBackground: EColors }>`
  display: flex;
  align-items: center;
  gap: 12px;
  width: max-content;
  padding: 8px 12px;
  border-radius: 12px;
  background-color: ${(props) => props.$wrapperBackground};
`;

export const SwitcherWrapperSmall = styled(SwitcherWrapper)`
  padding: 4px 12px;
`;

export const Tittle = styled(SText)<{ $textColor: EColors }>`
  font-size: 14px;
  font-style: normal;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${(props) => props.$textColor};
`;

export const TittleSmall = styled(Tittle)`
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.3px;
`;
