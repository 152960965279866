import { type FC, useEffect, useState } from 'react';
import { ReactComponent as DownloadIcon } from 'assets/icons/button-icons/download-simple.svg';
import { clearInvoicePdfLinkDataAction } from 'store/Invoice-pdf-links-reducer/Invoice-pdf-links.reducer';
import { fetchInvoicePdfLinkAction } from 'store/Invoice-pdf-links-reducer/Invoice-pdf-links.thunk-action';
import { useAppDispatch } from 'store/store';

import { SSpinnerBlue } from 'components/styled/SSpinnerBlue';

import * as S from './DownloadPdfText.styled';

interface IPropsDownloadPdfText {
  orderNumber: number;
  invoiceFile: string | null;
  t: (key: string) => string;
}

export const DownloadPdfText: FC<IPropsDownloadPdfText> = ({
  orderNumber,
  invoiceFile,
  t,
}): JSX.Element => {
  const [isPending, setIsPending] = useState(false);
  const dispatch = useAppDispatch();

  const handleTextClick = async (orderNumber: number) => {
    setIsPending(true);
    dispatch(clearInvoicePdfLinkDataAction());

    if (invoiceFile) {
      window.open(invoiceFile, '_blank');
      setIsPending(false);
      return;
    }
    const invoicePdfString = await dispatch(fetchInvoicePdfLinkAction(orderNumber)).unwrap();

    if (invoicePdfString) {
      window.open(invoicePdfString, '_blank');
      setIsPending(false);
    }
  };

  useEffect(() => {
    return () => {
      dispatch(clearInvoicePdfLinkDataAction());
    };
  }, [dispatch]);

  return (
    <S.DownloadLink onClick={() => handleTextClick(orderNumber)}>
      {isPending ? <SSpinnerBlue width={16} height={16} /> : <DownloadIcon />}
      <S.InvoiceNumber>
        <S.Text>{t('profile.invoice')}</S.Text>
        <S.Text>#{orderNumber}</S.Text>
      </S.InvoiceNumber>
    </S.DownloadLink>
  );
};
