import React from 'react';

import * as S from './VerificationStatus.styled';

interface IVerificationStatusProps {
  label: string;
  statusText: string;
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  isVerified: boolean;
}

export const VerificationStatus: React.FC<IVerificationStatusProps> = ({
  label,
  statusText,
  Icon,
  isVerified,
}) => {
  return (
    <S.StatusWrapper>
      {label}
      <S.Status $isVerified={isVerified}>
        <Icon />
        {statusText}
      </S.Status>
    </S.StatusWrapper>
  );
};
