import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useMinerCardButton } from 'pages/PagePurchase/hook/useMinerCardButton';
import { IMinerDataMapped } from 'store/api/cuverse-api/purchase/types/purchase-active-miners.interface';
import { selectBtcUsdRate } from 'store/rates-reducer/rates.selectors';
import { EColors } from 'styles/style-variables/colors';

import { CartManageButton } from 'components/ui/CartManageButton';
import { cutFractionDigits } from 'utils/formatters/cut-fraction-digits.util';
import { formatUsdOutput } from 'utils/formatters/format-usd-output.util';
import { AppRoute } from 'utils/route/app-route';

import * as S from './MinerCard.styled';

interface MinerCardProps {
  minerData: IMinerDataMapped;
  t: (key: string) => string;
}

export const MinerCard: React.FC<MinerCardProps> = ({ minerData, t }) => {
  const {
    dailyElectricityFee,
    picture,
    model,
    hashrate,
    unitSymbol,
    price,
    powerConsumption,
    estDailyOutput,
    id,
  } = minerData;

  const btcUsdRate = useSelector(selectBtcUsdRate);

  const navigate = useNavigate();

  const {
    quantity,
    inputValue,
    isFocused,
    isCountInitial,
    mouseOnButton,
    availableMinersNumber,
    isActualSoldOut,
    handleBlur,
    handleIncrement,
    handleDecrement,
    handleInputChange,
    handleMouseOver,
    handleMouseLeave,
    handleKeyDown,
    handleOnFocus,
    cartId,
  } = useMinerCardButton({ minerData });

  const wattPerTh = (powerConsumption * 1000) / hashrate;

  //ROI calculation
  const DAYS_IN_YEAR = 365;
  const DISCOUNT_20 = 0.2;

  const feeWithDiscount = dailyElectricityFee - dailyElectricityFee * DISCOUNT_20;
  const dailyNetProfit = estDailyOutput * btcUsdRate - feeWithDiscount;
  const roi = (((dailyNetProfit * DAYS_IN_YEAR) / price) * 100).toFixed(1);

  const AVERAGE_DAYS_IN_MONTH = 30.5;
  const netProfitMonthly = dailyNetProfit * AVERAGE_DAYS_IN_MONTH;

  const handleInfoButtonClick = () => {
    navigate(AppRoute.PurchaseMinerInfo(), {
      state: {
        monthlyRevenue: netProfitMonthly,
        price: price,
        quantity: quantity || 0,
        availableMinersNumber: availableMinersNumber,
        isActualSoldOut: isActualSoldOut,
        hashRate: hashrate,
        energyEfficiency: wattPerTh,
        annualRoi: roi,
        monthlyIncome: estDailyOutput * btcUsdRate * 30,
        monthlyElectricityFee: dailyElectricityFee * 30,
        monthlyProfit: netProfitMonthly,
        dailyElectricityFee,
        estDailyOutput,
        btcUsdRate,
        minerData,
      },
    });
  };

  return (
    <S.MinerItem as="li">
      <S.MinerCardHeader as="header">
        <S.MinerPicture>
          <source srcSet={`${picture}, ${picture} 2x, ${picture} 3x`} type="image/webp" />
          <source srcSet={`${picture}, ${picture} 2x, ${picture} 3x`} type="image/png" />
          <img width={80} height={80} src={picture} alt="Miner" />
        </S.MinerPicture>
        <S.MinerHeaderTextContent as="dl">
          <S.MinerName as="dt">{model.split('-')[0]}</S.MinerName>
          <S.MinerSpecifications as="dd">
            <S.SpecColumn>
              <S.SpecTittle>{t('purchase.hashRate')}</S.SpecTittle>
              <S.DescriptionValue>
                {hashrate} {unitSymbol}
              </S.DescriptionValue>
            </S.SpecColumn>
            <S.SpecColumn>
              <S.SpecTittle>{t('purchase.efficiency')}</S.SpecTittle>
              <S.DescriptionValue>{formatUsdOutput(wattPerTh)} W/TH</S.DescriptionValue>
            </S.SpecColumn>
          </S.MinerSpecifications>
        </S.MinerHeaderTextContent>
      </S.MinerCardHeader>
      <S.MinerCardRevenueContent as="section">
        <S.SpecRow as="dl">
          <S.SpecTittle as="dt">{t('purchase.monthlyProfit')}</S.SpecTittle>
          <S.SpecRowDescription as="dd">
            <S.DescriptionText>{t('purchase.upTo')}</S.DescriptionText>
            <S.DescriptionRowValue>${formatUsdOutput(netProfitMonthly)}</S.DescriptionRowValue>
          </S.SpecRowDescription>
        </S.SpecRow>
        <S.SpecRow as="dl">
          <S.SpecTittle as="dt">{t('purchase.annualRoi')}</S.SpecTittle>
          <S.SpecRowDescription as="dd">
            <S.DescriptionText>{t('purchase.upTo')}</S.DescriptionText>
            <S.DescriptionRowValue>{formatUsdOutput(roi)}%</S.DescriptionRowValue>
          </S.SpecRowDescription>
        </S.SpecRow>
        <S.SpecHr />
        <S.SpecRow as="dl">
          <S.SpecTittle as="dt">{t('purchase.minerPrice')}</S.SpecTittle>
          <S.DescriptionRowValue as="dd">${formatUsdOutput(price)}</S.DescriptionRowValue>
        </S.SpecRow>
      </S.MinerCardRevenueContent>
      <S.ButtonsWrapper as="footer">
        {/* <S.MinerInfoButton variant="bordered" text="Miner info" onClick={handleInfoButtonClick} /> */}
        <CartManageButton
          quantity={quantity || 0}
          inputValue={inputValue}
          handleIncrement={handleIncrement}
          handleDecrement={handleDecrement}
          handleInputChange={handleInputChange}
          handleOnMouseOver={handleMouseOver}
          handleOnMouseLeave={handleMouseLeave}
          handleOnKeyDown={handleKeyDown}
          handleOnFocus={handleOnFocus}
          handleOnBlur={handleBlur}
          isMouseOnButton={mouseOnButton}
          isInitial={isCountInitial}
          availableMinersNumber={availableMinersNumber}
          inputBackgroundColor={isFocused ? EColors.White : EColors.Yellow3}
          isSoldOut={isActualSoldOut}
          t={t}
        />
      </S.ButtonsWrapper>
    </S.MinerItem>
  );
};
