import React from 'react';
import { ReactComponent as DownloadIcon } from 'assets/icons/purchase-page-icons/download-white.svg';
import { EPurchaseStep } from 'store/api/cuverse-api/purchase/types/purchase-step.type';
import { EColors } from 'styles/style-variables/colors';

import * as S from './InvoiceHeader.styled';

interface InvoiceHeaderProps {
  invoiceNumber?: number;
  invoiceDate?: string;
  handlePdfClick?: () => void;
  step: EPurchaseStep;
  t: (key: string) => string;
}

export const InvoiceHeader: React.FC<InvoiceHeaderProps> = ({
  invoiceNumber,
  invoiceDate,
  handlePdfClick,
  step,
  t,
}) => {
  const [date, time] = invoiceDate?.split(' ') || ['', ''];

  return (
    <S.Container step={step}>
      <S.Title>Order #{invoiceNumber}</S.Title>
      {step === 'paid-success' && (
        <S.PdfButton
          variant="bordered"
          text={t('purchase.saveAsPdfText')}
          icon={<DownloadIcon />}
          onClick={handlePdfClick}
          svgProps={{
            svgFill: EColors.White,
          }}
        />
      )}
      <S.InvoiceDate>
        {t('purchase.createdText')} {date} {t('purchase.atText')} {time}
      </S.InvoiceDate>
    </S.Container>
  );
};
