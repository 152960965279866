import { css, styled } from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { EColors } from 'styles/style-variables/colors';

export const StyledOrdersHistoryWrapper = styled.div<{ $isDesktop: boolean }>`
  display: flex;
  flex: 1 0;
  flex-direction: column;
  border-radius: 24px;

  ${({ $isDesktop }) =>
    $isDesktop &&
    css`
      padding: 24px;
      background-color: ${EColors.White};

      @media (max-width: ${BreakPoint.MobileTop}) {
        padding: 16px;
      }
    `}

  @media (max-width: ${BreakPoint.LaptopTop}) {
    border-radius: 16px;
  }
`;
