import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ClockDarkIcon } from 'assets/icons/button-icons/clock-dark.svg';
import { ReactComponent as ClockWhiteIcon } from 'assets/icons/button-icons/clock-white.svg';
import { DAY_14_IN_SECONDS } from 'constants/time/day-14-in-seconds';
import { SECOND_IN_MILLISECONDS } from 'constants/time/second-in-milliseconds.const';
import { useAppDispatch } from 'store/store';
import { setCooldown } from 'store/uptime-reducer/uptime.reducer';
import { requestUptimeThunkAction } from 'store/uptime-reducer/uptime.thunk-action';
import { EColors } from 'styles/style-variables/colors';

import { Button } from 'components/ui/Button';
import { getTimeLeft } from 'utils/common/time-left';

export const enum ECooldownType {
  inModal = 'inModal',
  Assets = 'Assets',
  PaymentSuccess = 'PaymentSuccess',
}

interface LimitedUptimeButtonProps {
  cooldownType?: ECooldownType;
  lastUptimeToggle: string | null;
}

let intervalId: NodeJS.Timer | undefined;

export const CooldownExplainer: React.FC<LimitedUptimeButtonProps> = ({
  cooldownType,
  lastUptimeToggle,
}) => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();

  const [calculatedTimeLeft, setCalculatedTimeLeft] = useState('');

  useEffect(() => {
    if (!intervalId) {
      intervalId = setInterval(() => {
        const timeLeft = getTimeLeft(lastUptimeToggle || '0', DAY_14_IN_SECONDS, t, i18n);
        const second = t('time.second').toLocaleLowerCase(i18n.language);
        const seconds = t('time.seconds').toLocaleLowerCase(i18n.language);
        if (timeLeft.endsWith(second) || timeLeft.endsWith(seconds)) {
          setCalculatedTimeLeft(timeLeft);
        }
      }, SECOND_IN_MILLISECONDS);
    }

    return () => {
      clearInterval(intervalId);
      intervalId = undefined;
    };
  }, [lastUptimeToggle, t, i18n]);

  useEffect(() => {
    setCalculatedTimeLeft(getTimeLeft(lastUptimeToggle || '0', DAY_14_IN_SECONDS, t, i18n));
  }, [i18n, i18n.language, lastUptimeToggle, t]);

  useEffect(() => {
    if (calculatedTimeLeft && calculatedTimeLeft.startsWith('0')) {
      void dispatch(requestUptimeThunkAction());
      dispatch(setCooldown(false));
    }
  }, [calculatedTimeLeft, dispatch]);

  const CooldownExplainerComponents: Record<ECooldownType, JSX.Element | null> = {
    [ECooldownType.inModal]: (
      <Button
        style={{ fontSize: '10px' }}
        variant={'primary-gray'}
        text={t('uptime.youWillBeAbleToChangeUptimeModeIn') + calculatedTimeLeft}
        icon={<ClockDarkIcon />}
        svgProps={{
          svgFill: 'transparent',
        }}
        disabled
      />
    ),
    [ECooldownType.Assets]: (
      <Button
        style={{
          fontSize: '12px',
          backgroundColor: EColors.Blue1OldBrand,
          color: EColors.White,
        }}
        variant={'primary-gray'}
        text={t('uptime.youWillBeAbleToChangeUptimeModeIn') + calculatedTimeLeft}
        icon={<ClockWhiteIcon />}
        svgProps={{
          svgFill: 'transparent',
          svgStroke: EColors.White,
        }}
        disabled
      />
    ),
    [ECooldownType.PaymentSuccess]: (
      <Button
        style={{
          fontSize: '10px',
          backgroundColor: EColors.Gray5,
          color: EColors.Gray1Text,
        }}
        variant={'primary-gray'}
        text={t('uptime.youWillBeAbleToChangeUptimeModeIn') + calculatedTimeLeft}
        icon={<ClockDarkIcon />}
        svgProps={{
          svgFill: 'transparent',
          svgStroke: EColors.Gray1Text,
        }}
        disabled
      />
    ),
  };

  if (calculatedTimeLeft && calculatedTimeLeft.split('')[0] === '0') return null;

  if (!cooldownType) return CooldownExplainerComponents[ECooldownType.inModal];

  return CooldownExplainerComponents[cooldownType];
};
