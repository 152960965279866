import { styled } from 'styled-components';
import { EColors } from 'styles/style-variables/colors';

import { SText } from 'components/styled/SText';

export const DownloadLink = styled(SText)`
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
`;

export const InvoiceNumber = styled(SText)`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const Text = styled(SText)`
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color: ${EColors.Blue2NewBrand};
`;
