import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { FontFamily } from 'styles/style-variables/font-family';

export const StatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  font-family: ${FontFamily.Montserrat};
  font-size: 12px;
  color: #646464;

  @media (max-width: ${BreakPoint.MobileTop}) {
    align-items: flex-start;
  }
`;

export const Status = styled.div<{ $isVerified: boolean }>`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px 8px;
  border-radius: 12px;
  background-color: ${({ $isVerified }) => ($isVerified ? '#E0FBED' : '#fff6d9')};
  font-size: 14px;
  font-weight: 500;

  & svg {
    width: 16px;
    height: 16px;
  }
`;
