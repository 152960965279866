import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { FallbackInvoiceMessages } from 'constants/notification-messages/fallback-invoice-messages';
import { applyCartCoupon, requestInvoice } from 'store/api/cuverse-api/invoice/invoice.service';
import { ICartCouponError } from 'store/api/cuverse-api/invoice/types/cart-coupon-error.interface';
import {
  ICouponResponse,
  ICouponResponseDataMapped,
} from 'store/api/cuverse-api/invoice/types/cart-coupon-response.interface';
import { IInvoiceError } from 'store/api/cuverse-api/invoice/types/invoice-error.interface';
import {
  IInvoiceResponse,
  IInvoiceResponseDataMapped,
} from 'store/api/cuverse-api/invoice/types/invoice-response.interface';
import { EPromoCodeCategories } from 'store/promocode-reducer/constants/promocode-categories';

import { mapCouponData, mapInvoiceData } from 'utils/mapper/mapper';
import { notifyError, notifySuccess } from 'utils/notify/notify.utils';

export const fetchInvoiceThunkAction = createAsyncThunk(
  'invoiceCheckout/fetch',
  async (purchaseId: number): Promise<IInvoiceResponseDataMapped> => {
    return await requestInvoice({ purchase_id: purchaseId, operation_type: 'pool_miner' })
      .then((response: IInvoiceResponse) => {
        notifySuccess(FallbackInvoiceMessages.SuccessfullyCreatedInvoice);
        return mapInvoiceData(response.data);
      })
      .catch((error: AxiosError<IInvoiceError>) => {
        const serverErrorResponse = error.response?.data;
        if (serverErrorResponse?.message) {
          notifyError(serverErrorResponse.message);
          throw error;
        }
        if (serverErrorResponse?.data) {
          notifyError(serverErrorResponse.data);
          throw error;
        }
        throw error;
      });
  },
);

// export const applyCartPromoThunkAction = createAsyncThunk(
//   'invoiceCheckout/applyCartPromo',
//   async ({
//     purchaseId,
//     category,
//     couponCode,
//   }: {
//     purchaseId: number;
//     category: EPromoCodeCategories;
//     couponCode: string;
//   }): Promise<ICouponResponseDataMapped> => {
//     return await applyCartCoupon({
//       purchase_id: purchaseId,
//       coupon_code: couponCode,
//       coupon_type: category,
//     })
//       .then((response: ICouponResponse) => {
//         const errors = response.data.errors;
//         if (errors) {
//           Object.keys(errors).map((miner) => notifyError(`${miner}: ${errors[miner]}`));
//           throw new Error();
//         }
//         notifySuccess(FallbackInvoiceMessages.PromoCodeAccepted);
//         return mapCouponData(response.data);
//       })
//       .catch((error: AxiosError<ICartCouponError>) => {
//         if (error.response?.data.data) {
//           notifyError(error.response.data.data);
//         }
//         if (error.response?.data.message) {
//           notifyError(error.response.data.message);
//         }
//         throw error;
//       });
//   },
// );
