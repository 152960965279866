import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as QuestionIcon } from 'assets/icons/button-icons/question.svg';
import { useUptime } from 'hooks/useUptime';
import { openLimitedUptimeExplainerPopupAction } from 'store/modals-reducer/modals.reducer';
import { useAppDispatch } from 'store/store';
import { requestUptimeThunkAction } from 'store/uptime-reducer/uptime.thunk-action';
import { EColors } from 'styles/style-variables/colors';

import {
  CooldownExplainer,
  ECooldownType,
} from 'components/logic/CooldownExplainer/CooldownExplainer';
import { UptimeSwitcher } from 'components/logic/UptimeSwitcher';
import { EUptimeSwitcherVariants } from 'components/logic/UptimeSwitcher/UptimeSwitcher';
import { Switcher } from 'components/ui/Switcher';
import { ESwitcherSize } from 'components/ui/Switcher/types/switcher-props';

import * as S from './LimitedUptimeHeader.styled';

export const LimitedUptimeHeader: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const openUptimeLimitPopup = () => dispatch(openLimitedUptimeExplainerPopupAction());
  const {
    cooldown,
    uptime,
    lastUptimeToggle,
    uptimeStatus,
    handleLimitedUptimeDisabled,
    handleLimitedUptimeEnabled,
  } = useUptime();

  const isLoading = uptimeStatus === 'pending';
  const handleChange = () => {
    if (uptime) {
      void handleLimitedUptimeDisabled();
    } else {
      void handleLimitedUptimeEnabled();
    }
  };

  useEffect(() => {
    if (!lastUptimeToggle) {
      void dispatch(requestUptimeThunkAction());
    }
  }, [dispatch, lastUptimeToggle]);

  return (
    <S.LimitedUptimeHeaderWrapper>
      <S.ButtonsWrapper>
        <UptimeSwitcher
          uptime={uptime}
          cooldown={cooldown}
          isLoading={isLoading}
          handleChange={handleChange}
          size={ESwitcherSize.m}
          variant={EUptimeSwitcherVariants.AssetsHeader}
          t={t}
        />
        <S.HowItWorksButton
          text={t('uptime.howItWorks')}
          variant="borderless"
          icon={<QuestionIcon />}
          onClick={openUptimeLimitPopup}
          svgProps={{
            hoverSvgFill: 'transparent',
          }}
        />
      </S.ButtonsWrapper>
      {cooldown && (
        <CooldownExplainer
          cooldownType={ECooldownType.Assets}
          lastUptimeToggle={lastUptimeToggle}
        />
      )}
    </S.LimitedUptimeHeaderWrapper>
  );
};
