import React from 'react';
import { ReactComponent as QuestionIcon } from 'assets/icons/button-icons/question-gray.svg';
import { EPurchaseStep } from 'store/api/cuverse-api/purchase/types/purchase-step.type';

import { formatUsdOutput } from 'utils/formatters/format-usd-output.util';

import * as S from './PaymentDetails.styled';

interface IPaymentDetailsProps {
  sumUsd: number;
  feesAndTaxes: number;
  purchaseStep: EPurchaseStep;
  t: (key: string) => string;
}

export const PaymentDetails: React.FC<IPaymentDetailsProps> = ({
  sumUsd,
  feesAndTaxes,
  purchaseStep,
  t,
}) => {
  return (
    <S.PaymentDetailsWrapper>
      <S.PaymentData as="dl" $purchaseStep={purchaseStep}>
        <S.MinersTotalWrapper $purchaseStep={purchaseStep}>
          <S.Title as="dt">{t('purchase.minersTotal')}</S.Title>
          <S.DescriptionValue>{`$${formatUsdOutput(sumUsd)}`}</S.DescriptionValue>
        </S.MinersTotalWrapper>
        {feesAndTaxes > 0 && (
          <S.FeesAndTaxesWrapper $purchaseStep={purchaseStep}>
            <S.TittleWrapper>
              <S.Title as="dt">{t('purchase.feesAndTaxes')}</S.Title>
              <QuestionIcon />
            </S.TittleWrapper>
            <S.DescriptionValue>{`$${formatUsdOutput(feesAndTaxes)}`}</S.DescriptionValue>
          </S.FeesAndTaxesWrapper>
        )}
      </S.PaymentData>
    </S.PaymentDetailsWrapper>
  );
};
