import { AxiosResponse } from 'axios';
import { cuverseApi } from 'store/api/cuverse-api/cuverse.api';

import { TOrdersHistoryResponse } from './types/orders-history-response.interface';
import { IProfileResponse } from './types/profile-response.interface';
import { IUpdateProfileRequestBody } from './types/update-profile-request-body.interface';
import { IUpdateWalletRequestBody } from './types/update-wallet-request-body.interface';
import { IWalletApproveRequestBody } from './types/wallet-approve-request-body.interface';
import { IWalletApproveResponse } from './types/wallet-approve-response.interface';
import { IWalletsResponse } from './types/wallet-response.interface';
import { ProfileRoute } from './profile.route';

export const requestProfile = async (): Promise<IProfileResponse> =>
  cuverseApi
    .get<IProfileResponse, AxiosResponse<IProfileResponse>>(ProfileRoute.Profile())
    .then((res) => res?.data);

export const updateProfile = async (body: IUpdateProfileRequestBody): Promise<IProfileResponse> =>
  cuverseApi
    .patch<
      IProfileResponse,
      AxiosResponse<IProfileResponse>,
      IUpdateProfileRequestBody
    >(ProfileRoute.Profile(), body)
    .then((res) => res?.data);

export const requestWallets = async (): Promise<IWalletsResponse> =>
  cuverseApi
    .get<IWalletsResponse, AxiosResponse<IWalletsResponse>>(ProfileRoute.Wallet())
    .then((res) => res?.data);

export const requestWalletApprove = async (
  body: IWalletApproveRequestBody,
): Promise<IWalletApproveResponse> =>
  cuverseApi
    .post<
      IWalletApproveResponse,
      AxiosResponse<IWalletApproveResponse>,
      IWalletApproveRequestBody
    >(ProfileRoute.WalletApprove(), body)
    .then((res) => res?.data);

export const updateWallet = async (body: IUpdateWalletRequestBody): Promise<IWalletsResponse> =>
  cuverseApi
    .patch<
      IWalletsResponse,
      AxiosResponse<IWalletsResponse>,
      IUpdateWalletRequestBody
    >(ProfileRoute.Wallet(), body)
    .then((res) => res?.data);

export const requestOrdersHistory = async (): Promise<TOrdersHistoryResponse> =>
  cuverseApi.get(ProfileRoute.OrdersHistory()).then((res) => res?.data);
