import React, { useEffect } from 'react';
import { ReactComponent as Plus } from 'assets/icons/purchase-page-icons/plus-white.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/purchase-page-icons/warning-big.svg';
import { useUptime } from 'hooks/useUptime';
import { IMinersDataMapped } from 'store/api/cuverse-api/assets/types/assets-response.interface';
import { IMinerDataMapped } from 'store/api/cuverse-api/purchase/types/purchase-active-miners.interface';
import { useAppDispatch } from 'store/store';
import { requestUptimeThunkAction } from 'store/uptime-reducer/uptime.thunk-action';
import { EColors } from 'styles/style-variables/colors';

import { CooldownExplainer } from 'components/logic/CooldownExplainer';
import { ECooldownType } from 'components/logic/CooldownExplainer/CooldownExplainer';
import { UptimeProfitTable } from 'components/logic/UptimeProfitTable';
import { UptimeSwitcher } from 'components/logic/UptimeSwitcher';
import { EUptimeSwitcherVariants } from 'components/logic/UptimeSwitcher/UptimeSwitcher';
import { ESwitcherSize } from 'components/ui/Switcher/types/switcher-props';
import { mergeArraysById } from 'utils/common/arraysMergeByIdO1';
import { formatUsdOutput } from 'utils/formatters/format-usd-output.util';

import * as S from './InvoiceStatus.styled';

interface IInvoiceStatusProps {
  minersPrices: { id: number; price: number }[];
  btcUsdRate: number;
  assetsData: IMinersDataMapped[];
  launchDate?: string;
  paymentBalanceUsd: number;
  availableDays: number;
  handleReplenishClick: () => void;
  electricityFee: number;
  t: (key: string) => string;
}

export const InvoiceStatus: React.FC<IInvoiceStatusProps> = ({
  minersPrices,
  btcUsdRate,
  assetsData,
  launchDate,
  paymentBalanceUsd,
  availableDays,
  handleReplenishClick,
  t,
}) => {
  const dispatch = useAppDispatch();

  const {
    cooldown,
    uptime,
    lastUptimeToggle,
    uptimeStatus,
    handleLimitedUptimeDisabled,
    handleLimitedUptimeEnabled,
  } = useUptime();
  const isLoading = uptimeStatus === 'pending';

  const handleChange = () => {
    if (uptime) {
      void handleLimitedUptimeDisabled();
    } else {
      void handleLimitedUptimeEnabled();
    }
  };

  useEffect(() => {
    if (!lastUptimeToggle) {
      void dispatch(requestUptimeThunkAction());
    }
  }, [dispatch, lastUptimeToggle]);

  const DAYS_IN_YEAR = 365;
  const AVERAGE_DAYS_IN_MONTH = 30.5;
  const DISCOUNT_20 = 0.2;

  const transformAssetsData = assetsData.map((miner) => {
    return {
      ...miner,
      id: miner.productId,
    };
  });

  const groupMinersByMinerId = mergeArraysById(transformAssetsData, minersPrices);

  const totalMinersPrice = groupMinersByMinerId.reduce((a, b) => a + b.price * +b.number, 0);

  const dailyIncomesUsd = assetsData.map((miner) => miner.dailyOutput * btcUsdRate);
  const dailyTotalElectricityFees = assetsData.map((miner) => miner.dailyTotalFee);

  const totalDailyIncome = dailyIncomesUsd.reduce((a, b) => {
    if (!b) return a;
    return a + b;
  }, 0);

  const totalDailyElectricityFee = dailyTotalElectricityFees.reduce((a, b) => {
    if (!b) return a;
    return a + b;
  }, 0);

  const totalDailyElectricityFeeWithDiscount =
    totalDailyElectricityFee - totalDailyElectricityFee * DISCOUNT_20;

  const dailyNetProfit = totalDailyIncome - totalDailyElectricityFeeWithDiscount;

  const totalMonthlyIncome = totalDailyIncome * AVERAGE_DAYS_IN_MONTH;
  const totalMonthlyElectricityFee = totalDailyElectricityFeeWithDiscount * AVERAGE_DAYS_IN_MONTH;

  const monthlyProfit = totalMonthlyIncome - totalMonthlyElectricityFee;
  const annualRoi100 = ((dailyNetProfit * DAYS_IN_YEAR) / totalMinersPrice) * 100;
  const annualRoi65 = ((dailyNetProfit * DAYS_IN_YEAR * 0.65) / totalMinersPrice) * 100;

  return (
    <S.InvoiceStatus>
      <S.TittleBlock>
        <S.Title>{t('purchase.invoicePaidTitle')}</S.Title>
        <S.LaunchNotion>
          {t('purchase.minerDateStartText')} <S.LaunchDate>{launchDate}</S.LaunchDate>
        </S.LaunchNotion>
      </S.TittleBlock>
      {/* <S.UptimeBlock>
        <UptimeProfitTable
          t={t}
          monthlyElectricityFee={totalMonthlyElectricityFee}
          monthlyIncome={totalMonthlyIncome}
          monthlyProfit={monthlyProfit}
          annualRoi100={annualRoi100}
          annualRoi65={annualRoi65}
        />
        <S.UptimeSwitcherWrapper>
          <UptimeSwitcher
            cooldown={cooldown}
            uptime={uptime}
            isLoading={isLoading}
            handleChange={handleChange}
            size={ESwitcherSize.s}
            variant={EUptimeSwitcherVariants.PaymentSuccessScreen}
            wrapperBackground={EColors.Gray5}
            textColor={EColors.Gray1Text}
            t={t}
          />
          {cooldown && (
            <CooldownExplainer
              lastUptimeToggle={lastUptimeToggle}
              cooldownType={ECooldownType.PaymentSuccess}
            />
          )}
        </S.UptimeSwitcherWrapper>
      </S.UptimeBlock> */}
      <S.WarningBlock>
        <WarningIcon />
        <S.WarningText>
          {t('purchase.electricityFeeNotificationText')}{' '}
          <S.WarningSpan>(${formatUsdOutput(totalDailyElectricityFee)})</S.WarningSpan>
        </S.WarningText>
      </S.WarningBlock>
      <S.ElectricityBalanceBlock as="dl">
        <S.BalanceTitle as="dt">{t('purchase.electricityBalanceTitle')}</S.BalanceTitle>
        <S.BalanceValue as="dd">
          <S.BalanceUsd>{`$${formatUsdOutput(paymentBalanceUsd)}`}</S.BalanceUsd>
          <S.BalanceDays>{availableDays} Days</S.BalanceDays>
        </S.BalanceValue>
        <S.ReplenishButton
          text={t('deposit.deposit')}
          icon={<Plus />}
          onClick={handleReplenishClick}
        />
      </S.ElectricityBalanceBlock>
    </S.InvoiceStatus>
  );
};
