import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { InvoiceStatus } from 'pages/PagePurchase/components/logic/InvoiceStatus';
import { PaymentSection } from 'pages/PagePurchase/components/logic/PaymentSection/PaymentSection';
import { SContainer, SInvoiceWrapper } from 'pages/PagePurchase/components/styled';
import { InvoiceHeader } from 'pages/PagePurchase/components/ui/InvoiceHeader';
import { EPromoCodeStatus } from 'pages/PagePurchase/constants/promo-code-status.const';
import { EPurchaseStep } from 'store/api/cuverse-api/purchase/types/purchase-step.type';
import { selectAssets } from 'store/assets-reducer/assets.selectors';
import { requestAssetsData } from 'store/assets-reducer/assets.thunk-actions';
import { fetchCartThunkAction } from 'store/cart-reducer/cart.thunk-actions';
import { clearCartAction } from 'store/cart-reducer/cart-reducer';
import { selectCart } from 'store/cart-reducer/cart-selectors';
import { selectDashboardElectricity } from 'store/dashboard-reducer/dashboard.selectors';
import { clearInvoicePdfLinksDataAction } from 'store/Invoice-pdf-links-reducer/Invoice-pdf-links.reducer';
import { selectInvoicePdfLinks } from 'store/Invoice-pdf-links-reducer/Invoice-pdf-links.selectors';
import { selectInvoiceData } from 'store/invoice-reducer/invoice.selectors';
import { openDepositElectricityPopupAction } from 'store/modals-reducer/modals.reducer';
import { selectPaymentOrdersIds } from 'store/payment-reducer/payment.selectors';
import { setPromoStatusAction } from 'store/promocode-reducer/promocode.reducer';
import { selectActiveMiners } from 'store/purchase-reducer/purchase.selectors';
import { fetchActiveMinersThunkAction } from 'store/purchase-reducer/purchase.thunk-actions';
import { selectBtcUsdRate } from 'store/rates-reducer/rates.selectors';
import { useAppDispatch } from 'store/store';

import { SVisuallyHidden } from 'components/styled/SVisuallyHidden';
import { getTotalFeePendingMiners } from 'utils/common/get-total-fee-pending-miners.utils';
import { gtmClickReplenishPaid } from 'utils/gtmSender/gtmSender';
import { AppRoute } from 'utils/route/app-route';

export const PaymentSuccessScreen: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const invoiceData = useSelector(selectInvoiceData);
  const btcUsdRate = useSelector(selectBtcUsdRate);
  const minersData = useSelector(selectActiveMiners);
  const assetsData = useSelector(selectAssets);
  const cartData = useSelector(selectCart);

  const { balance, availableDays } = useSelector(selectDashboardElectricity);
  const pdfLinks: string[] = useSelector(selectInvoicePdfLinks);
  const paymentOrdersIds = useSelector(selectPaymentOrdersIds);

  const { cartItems } = cartData;

  const launchDate = invoiceData?.startAt;
  const totalDailyElectricityFeePendingMiners = getTotalFeePendingMiners(minersData, cartItems);

  const minersPrices = minersData.map((minerData) => ({
    id: minerData.id,
    price: minerData.price,
  }));
  const links = paymentOrdersIds ? pdfLinks.slice(-paymentOrdersIds.length) : pdfLinks;

  const handlePdfClick = () => {
    links.forEach((url) => window.open(url, '_blank'));
    dispatch(clearInvoicePdfLinksDataAction());
  };
  const handleReplenishButtonClick = () => {
    dispatch(openDepositElectricityPopupAction());
    gtmClickReplenishPaid();
  };

  useEffect(() => {
    void dispatch(requestAssetsData());
    void dispatch(fetchActiveMinersThunkAction());
  }, [dispatch]);

  useEffect(() => {
    return () => {
      void dispatch(clearCartAction());
      void dispatch(fetchCartThunkAction());

      dispatch(setPromoStatusAction(EPromoCodeStatus.Button));
    };
  }, [dispatch]);

  return (
    <>
      {!cartData.cartId ? (
        <Navigate to={AppRoute.Purchase()} replace />
      ) : (
        <>
          <InvoiceHeader
            step={EPurchaseStep.PaidSuccess}
            invoiceNumber={invoiceData?.id}
            invoiceDate={invoiceData?.createdAt}
            handlePdfClick={handlePdfClick}
            t={t}
          />
          <SContainer>
            <SInvoiceWrapper as="section">
              <SVisuallyHidden as="h2">Payment details</SVisuallyHidden>
              <InvoiceStatus
                minersPrices={minersPrices}
                btcUsdRate={btcUsdRate}
                assetsData={assetsData}
                launchDate={launchDate}
                paymentBalanceUsd={balance}
                availableDays={availableDays}
                handleReplenishClick={handleReplenishButtonClick}
                electricityFee={totalDailyElectricityFeePendingMiners}
                t={t}
              />
              <PaymentSection
                purchaseStep={EPurchaseStep.PaidSuccess}
                t={t}
                invoiceData={invoiceData}
              />
            </SInvoiceWrapper>
          </SContainer>
        </>
      )}
    </>
  );
};
