import type { FC } from 'react';
import { ReactComponent as DownloadIcon } from 'assets/icons/button-icons/download-simple.svg';
import dayjs from 'dayjs';
import { DownloadPdfText } from 'pages/PageProfile/components/logic/DownloadPdfText';
import { IOrdersHistoryDataMapped } from 'store/api/cuverse-api/profile/types/orders-history-response.interface';

import { formatUsdOutput } from 'utils/formatters/format-usd-output.util';

import * as S from './OrdersHistoryMobile.styled';

interface IPropsOrdersHistoryMobile {
  t: (key: string) => string;
  ordersHistory: IOrdersHistoryDataMapped[];
}

export const OrdersHistoryMobile: FC<IPropsOrdersHistoryMobile> = ({
  t,
  ordersHistory,
}): JSX.Element => {
  return (
    <S.OrdersList as="ul">
      {ordersHistory.map(({ date, orderNumber, order, total, invoiceFile }) => (
        <S.OrderListItem key={orderNumber} as="li">
          <S.Row>
            <S.Description>
              <S.Tittle>{t('profile.date')}</S.Tittle>
            </S.Description>
            <S.Values>
              <S.Value>{dayjs.unix(date).utc().format('DD.MM.YYYY')}</S.Value>
            </S.Values>
          </S.Row>
          <S.Row>
            <S.Description>
              <S.Tittle>{t('profile.orderNumber')}</S.Tittle>
            </S.Description>
            <S.Values>
              <S.Value>{orderNumber}</S.Value>
            </S.Values>
          </S.Row>
          <S.Row>
            <S.Description>
              <S.Tittle>{t('profile.order')}</S.Tittle>
            </S.Description>
            <S.Values>
              <S.Value>{order}</S.Value>
            </S.Values>
          </S.Row>
          <S.Row>
            <S.Description>
              <S.Tittle>{t('profile.total')}</S.Tittle>
            </S.Description>
            <S.Values>
              <S.Value>${formatUsdOutput(total)}</S.Value>
            </S.Values>
          </S.Row>

          <S.Row>
            <S.Description>
              <S.Tittle>{t('profile.invoice')}</S.Tittle>
            </S.Description>
            <S.Values>
              <S.Value>
                <DownloadPdfText orderNumber={orderNumber} invoiceFile={invoiceFile} t={t} />
              </S.Value>
            </S.Values>
          </S.Row>
        </S.OrderListItem>
      ))}
    </S.OrdersList>
  );
};
