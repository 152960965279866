import React, { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ReactComponent as CheckIcon } from 'assets/icons/button-icons/check.svg';
import { FormApi } from 'final-form';
import { SStyledTabContentWrapper } from 'pages/PageProfile/components/styled/SStyledTabContentWrapper';
import { aryIannaTimeZones } from 'pages/PageProfile/constants/timezones.const';
import { getSelectItemsFromTimezones } from 'pages/PageProfile/utils/get-select-items-from-timezones.util';
import { IUpdateProfileRequestBody } from 'store/api/cuverse-api/profile/types/update-profile-request-body.interface';
import { selectProfile, selectProfileStatus } from 'store/profile-reducer/profile.selectors';
import { updateProfileDataThunkAction } from 'store/profile-reducer/profile.thunk-actions';
import { useAppDispatch } from 'store/store';
import { IPersonalInfoFormValues } from 'types/ui/ProfileTabs/personal-info-form-values.interface';

import { CustomDatePicker } from 'components/ui/CustomDatePicker';
import { Input } from 'components/ui/Input';
import { Select } from 'components/ui/Select';
import { TextArea } from 'components/ui/TextArea';
import { getIsBasicVerificationStatus } from 'utils/common/get-is-basic-verification-status.util';
import { getIsFullVerificationStatus } from 'utils/common/get-is-full-verification-status.util';
import { removeNonNumericSymbols } from 'utils/formatters/remove-non-numeric-symbols.util';
import { composeValidators } from 'utils/validators/compose-validators';
import { isNumber } from 'utils/validators/is-number';
import { isRequired } from 'utils/validators/is-required';

import * as S from './PersonalInfoForm.styled';

export const PersonalInfoForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const { verificationStatusCode } = useSelector(selectProfile);
  const isBasicVerification = getIsBasicVerificationStatus(verificationStatusCode);
  const isFullVerification = getIsFullVerificationStatus(verificationStatusCode);
  const { t } = useTranslation();

  const profile = useSelector(selectProfile);
  const profileFetchStatus = useSelector(selectProfileStatus);
  const isProfileDataPending = profileFetchStatus === 'pending';
  const [birthDate, setBirthDate] = useState<string | null>(profile.birthdate || null);
  const [timeZone, setTimeZone] = useState(profile.timezone);
  const [address, setAddress] = useState(profile.address);

  const timeZones = getSelectItemsFromTimezones(aryIannaTimeZones);

  const handleBirthDateChange = (
    value: string,
    form: FormApi<IPersonalInfoFormValues, Partial<IPersonalInfoFormValues>>,
  ) => {
    setBirthDate(value);
    form.change('birthdate', value);
  };

  const handlePostalCodeChange = (
    value: string,
    form: FormApi<IPersonalInfoFormValues, Partial<IPersonalInfoFormValues>>,
  ) => form.change('zip', removeNonNumericSymbols(value));

  const handleMailingAddressChange = (
    value: string,
    form: FormApi<IPersonalInfoFormValues, Partial<IPersonalInfoFormValues>>,
  ) => {
    setAddress(value);
    form.change('address', value);
  };

  const handleTimeZoneChange = (
    value: string,
    form: FormApi<IPersonalInfoFormValues, Partial<IPersonalInfoFormValues>>,
  ) => {
    setTimeZone(value);
    form.change('timezone', value);
  };

  const handleFormSubmit = (values: IPersonalInfoFormValues) => {
    if (!isProfileDataPending) {
      const { firstname, lastname, birthdate, country, state, city, zip, address, timezone } =
        values;
      const data: IUpdateProfileRequestBody = {
        firstname,
        lastname,
        dob: birthdate,
        country,
        state,
        city,
        zip,
        address1: address,
        timezone,
      };
      void dispatch(updateProfileDataThunkAction(data));
    }
  };

  useEffect(() => {
    setBirthDate(profile.birthdate);
    setTimeZone(profile.timezone);
    setAddress(profile.address);
  }, [profile]);

  return (
    <SStyledTabContentWrapper>
      <Form onSubmit={handleFormSubmit} initialValues={profile}>
        {({ handleSubmit, submitFailed, hasValidationErrors, form, pristine }) => (
          <S.Form onSubmit={handleSubmit} $isFailed={submitFailed}>
            <S.FormSection>
              <S.FormSectionTitle>{t('profile.personalInformationTitle')}</S.FormSectionTitle>
              <S.PersonalFieldsWrapper>
                <Field name="firstname" validate={composeValidators([isRequired])}>
                  {({ input, meta }) => (
                    <S.InputLabel>
                      <S.LabelText>{t('profile.firstNameLabel')}</S.LabelText>
                      <Input
                        {...input}
                        isReadOnly={isBasicVerification || isFullVerification}
                        type="text"
                        hasErrors={meta.error && submitFailed}
                      />
                      {meta.error && submitFailed && <S.ErrorText>{meta.error}</S.ErrorText>}
                    </S.InputLabel>
                  )}
                </Field>

                <Field name="lastname" validate={composeValidators([isRequired])}>
                  {({ input, meta }) => (
                    <S.InputLabel>
                      <S.LabelText>{t('profile.lastNameLabel')}</S.LabelText>
                      <Input
                        {...input}
                        isReadOnly={isBasicVerification || isFullVerification}
                        type="text"
                        hasErrors={meta.error && submitFailed}
                      />
                      {meta.error && submitFailed && <S.ErrorText>{meta.error}</S.ErrorText>}
                    </S.InputLabel>
                  )}
                </Field>

                <Field name="birthdate" validate={composeValidators([isRequired])}>
                  {({ meta }) => (
                    <S.DatePickerWrapper>
                      <S.DatePickerLabel htmlFor="birth-date-picker">
                        <S.LabelText>{t('profile.birthDateLabel')}</S.LabelText>
                      </S.DatePickerLabel>
                      <CustomDatePicker
                        isVerifyPassed={isBasicVerification || isFullVerification}
                        currentDateIsoString={birthDate}
                        onDateChange={(date) => handleBirthDateChange(date, form)}
                        id="birth-date-picker"
                        hasErrors={meta.error && submitFailed}
                      />
                      {meta.error && submitFailed && <S.ErrorText>{meta.error}</S.ErrorText>}
                    </S.DatePickerWrapper>
                  )}
                </Field>

                <Field name="email">
                  {({ input }) => (
                    <S.EmailLabel>
                      <S.LabelText>{t('profile.emailLabel')}</S.LabelText>
                      <Input {...input} type="text" isReadOnly={true} />
                    </S.EmailLabel>
                  )}
                </Field>
              </S.PersonalFieldsWrapper>
            </S.FormSection>

            <S.FormSection>
              <S.FormSectionTitle>{t('profile.addressTitle')}</S.FormSectionTitle>
              <S.AddressFieldsWrapper>
                <S.HomeAddressWrapper>
                  <Field name="country" validate={composeValidators([isRequired])}>
                    {({ input, meta }) => (
                      <S.InputLabel>
                        <S.LabelText>{t('profile.countryLabel')}</S.LabelText>
                        <Input
                          {...input}
                          isReadOnly={isFullVerification}
                          type="text"
                          hasErrors={meta.error && submitFailed}
                        />
                        {meta.error && submitFailed && <S.ErrorText>{meta.error}</S.ErrorText>}
                      </S.InputLabel>
                    )}
                  </Field>

                  <Field name="state" validate={composeValidators([isRequired])}>
                    {({ input, meta }) => (
                      <S.InputLabel>
                        <S.LabelText>{t('profile.stateLabel')}</S.LabelText>
                        <Input
                          {...input}
                          isReadOnly={isFullVerification}
                          type="text"
                          hasErrors={meta.error && submitFailed}
                        />
                        {meta.error && submitFailed && <S.ErrorText>{meta.error}</S.ErrorText>}
                      </S.InputLabel>
                    )}
                  </Field>

                  <Field name="city" validate={composeValidators([isRequired])}>
                    {({ input, meta }) => (
                      <S.InputLabel>
                        <S.LabelText>{t('profile.cityLabel')}</S.LabelText>
                        <Input
                          {...input}
                          isReadOnly={isFullVerification}
                          type="text"
                          hasErrors={meta.error && submitFailed}
                        />
                        {meta.error && submitFailed && <S.ErrorText>{meta.error}</S.ErrorText>}
                      </S.InputLabel>
                    )}
                  </Field>

                  <Field name="zip" validate={composeValidators([isRequired, isNumber])}>
                    {({ input, meta }) => (
                      <S.PostalCodeLabel>
                        <S.LabelText>{t('profile.zipCodeLabel')}</S.LabelText>
                        <Input
                          {...input}
                          isReadOnly={isFullVerification}
                          type="text"
                          onChange={(evt) => handlePostalCodeChange(evt.currentTarget.value, form)}
                          hasErrors={meta.error && submitFailed}
                        />
                        {meta.error && submitFailed && <S.ErrorText>{meta.error}</S.ErrorText>}
                      </S.PostalCodeLabel>
                    )}
                  </Field>
                </S.HomeAddressWrapper>

                <Field name="address" validate={composeValidators([isRequired])}>
                  {({ input, meta }) => (
                    <S.MailingAddressLabel>
                      <S.LabelText>{t('profile.mailingAddressLabel')}</S.LabelText>
                      <TextArea
                        {...input}
                        isVerifyPassed={isFullVerification}
                        textValue={address || ''}
                        handleTextAreaChange={(value) => handleMailingAddressChange(value, form)}
                        hasErrors={meta.error && submitFailed}
                      />
                      {meta.error && submitFailed && <S.ErrorText>{meta.error}</S.ErrorText>}
                    </S.MailingAddressLabel>
                  )}
                </Field>

                <Field name="timezone" validate={composeValidators([isRequired])}>
                  {({ input, meta }) => (
                    <S.TimeZoneSelectLabel>
                      <S.LabelText>{t('profile.timezoneLabel')}</S.LabelText>
                      <Select
                        {...input}
                        items={timeZones}
                        currentValue={timeZone}
                        onChange={(newValue) => handleTimeZoneChange(newValue, form)}
                      />
                      {meta.error && submitFailed && <S.ErrorText>{meta.error}</S.ErrorText>}
                    </S.TimeZoneSelectLabel>
                  )}
                </Field>
              </S.AddressFieldsWrapper>
            </S.FormSection>

            <S.SubmitButton
              type="submit"
              text={t('profile.saveChanges')}
              icon={<CheckIcon />}
              onSubmit={handleSubmit}
              disabled={pristine || (submitFailed && hasValidationErrors) || isProfileDataPending}
            />
          </S.Form>
        )}
      </Form>
    </SStyledTabContentWrapper>
  );
};
