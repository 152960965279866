import React, { useEffect, useRef, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ReactComponent as ResendIcon } from 'assets/icons/button-icons/refresh.svg';
import { SECOND_IN_MILLISECONDS } from 'constants/time/second-in-milliseconds.const';
import { FormApi } from 'final-form';
import { IUpdateWalletRequestBody } from 'store/api/cuverse-api/profile/types/update-wallet-request-body.interface';
import { IWalletApproveRequestBody } from 'store/api/cuverse-api/profile/types/wallet-approve-request-body.interface';
import {
  selectNewWalletAddress,
  selectProfile,
  selectWalletsStatus,
} from 'store/profile-reducer/profile.selectors';
import {
  requestWalletApproveOtpThunkAction,
  updateWalletsDataThunkAction,
} from 'store/profile-reducer/profile.thunk-actions';
import { useAppDispatch } from 'store/store';
import { IOtpFormValues } from 'types/ui/OtpForm/otp-form-values.interface';

import { RESEND_TIMEOUT_SECONDS } from 'components/ui/OtpForm/constants/resend-timeout-seconds.const';
import { getFormattedTimeDelay } from 'components/ui/OtpForm/utils/get-formatted-time-delay.util';
import { getTimerValueInSeconds } from 'components/ui/OtpForm/utils/get-timer-value-in-seconds.util';
import { hideEmailAddress } from 'utils/formatters/hide-email-address.util';
import { removeNonNumericSymbols } from 'utils/formatters/remove-non-numeric-symbols.util';
import { getEmailSendTime } from 'utils/storage/date-and-time/get-email-send-time.util';
import { setEmailSendTime } from 'utils/storage/date-and-time/set-email-send-time.util';
import { composeValidators } from 'utils/validators/compose-validators';
import { OTP_LENGTH } from 'utils/validators/constants/validation.constants';
import { hasExactLength } from 'utils/validators/has-exact-length';
import { isNumber } from 'utils/validators/is-number';
import { isRequired } from 'utils/validators/is-required';

import * as S from './ConfirmWalletChangeContent.styled';

export const ConfirmWalletChangeContent: React.FC = () => {
  const dispatch = useAppDispatch();
  const emailSendTime = getEmailSendTime();
  const initialTime = emailSendTime ? getTimerValueInSeconds(emailSendTime) : 0;
  const updateTimeIntervalRef = useRef<ReturnType<typeof setInterval>>();
  const { email } = useSelector(selectProfile);
  const newWalletAddress = useSelector(selectNewWalletAddress);
  const walletsFetchStatus = useSelector(selectWalletsStatus);
  const isWalletsDataPending = walletsFetchStatus === 'pending';
  const { t } = useTranslation();

  const [timeDelay, setTimeDelay] = useState<number>(initialTime);

  const handleFieldChange = (value: string, form: FormApi<IOtpFormValues>) =>
    form.change('code', removeNonNumericSymbols(value));

  const handleResendClick = (): void => {
    const body: IWalletApproveRequestBody = { type: 'btc_wallet' };
    void dispatch(requestWalletApproveOtpThunkAction(body));
    setEmailSendTime();
    setTimeDelay(RESEND_TIMEOUT_SECONDS);
  };

  const handleFormSubmit = (values: IOtpFormValues) => {
    if (!isWalletsDataPending) {
      const body: IUpdateWalletRequestBody = {
        wallet_type: 'btc',
        wallet_value: newWalletAddress || '',
        confirmation_code: values.code,
      };
      void dispatch(updateWalletsDataThunkAction(body));
    }
  };

  useEffect(() => {
    if (timeDelay > 0) {
      updateTimeIntervalRef.current = setInterval(() => {
        setTimeDelay((current) => current - 1);
      }, SECOND_IN_MILLISECONDS);
    }
    return () => clearInterval(updateTimeIntervalRef.current);
  }, [timeDelay]);

  return (
    <S.Container>
      <S.InstructionText>{t('profile.instructionText')}</S.InstructionText>

      <Form onSubmit={handleFormSubmit}>
        {({ handleSubmit, submitFailed, hasValidationErrors, form }) => (
          <S.Form onSubmit={handleSubmit} $isFailed={submitFailed}>
            <Field
              name="code"
              validate={composeValidators([isRequired, isNumber, hasExactLength(OTP_LENGTH)])}
            >
              {({ input, meta }) => (
                <S.InputLabel>
                  <S.LabelText>
                    {t('profile.codeReceivedOnEmailLabel')}
                    <br />
                    {hideEmailAddress(email)}
                  </S.LabelText>
                  <S.InputWrapper>
                    <S.OtpInput
                      {...input}
                      type="text"
                      hasErrors={meta.error && submitFailed}
                      onChange={(evt) => handleFieldChange(evt.currentTarget.value, form)}
                      hasValidationIcons={true}
                      autoComplete="off"
                      maxLength={OTP_LENGTH}
                    />
                    <S.ResendCodeButton
                      type="button"
                      variant="secondary"
                      text={
                        timeDelay
                          ? `Repeat in ${getFormattedTimeDelay(timeDelay)}`
                          : 'Send it again'
                      }
                      icon={timeDelay ? undefined : <ResendIcon />}
                      isReversed={true}
                      onClick={handleResendClick}
                      disabled={!!timeDelay || isWalletsDataPending}
                    />
                  </S.InputWrapper>
                  {meta.error && submitFailed && <S.ErrorText>{meta.error}</S.ErrorText>}
                </S.InputLabel>
              )}
            </Field>

            <S.SubmitButton
              type="submit"
              text="Confirm"
              disabled={(submitFailed && hasValidationErrors) || isWalletsDataPending}
            />
          </S.Form>
        )}
      </Form>

      <S.CheckSpamNote>{t('profile.checkSpamText')}</S.CheckSpamNote>
    </S.Container>
  );
};
