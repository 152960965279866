import { uptime } from 'os';

import type { ChangeEvent, FC } from 'react';
import { EColors } from 'styles/style-variables/colors';

import { Switcher } from 'components/ui/Switcher';
import { ESwitcherSize } from 'components/ui/Switcher/types/switcher-props';

import * as S from './UptimeSwitcher.styled';

export const enum EUptimeSwitcherVariants {
  AssetsHeader = 'AssetsHeader',
  PaymentSuccessScreen = 'PaymentSuccessScreen',
}

interface IPropsUptimeSwitcher {
  variant: EUptimeSwitcherVariants;
  isLoading?: boolean;
  cooldown?: boolean;
  uptime: boolean;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  size: ESwitcherSize;
  wrapperBackground?: EColors;
  textColor?: EColors;
  t: (key: string) => string;
}

export const UptimeSwitcher: FC<IPropsUptimeSwitcher> = ({
  isLoading,
  variant,
  uptime,
  cooldown,
  wrapperBackground = EColors.Blue4,
  textColor = EColors.White,
  handleChange,
  t,
}): JSX.Element => {
  const uptimeSwitcherComponent: Record<EUptimeSwitcherVariants, () => JSX.Element> = {
    [EUptimeSwitcherVariants.AssetsHeader]: () => (
      <S.SwitcherWrapper $wrapperBackground={wrapperBackground}>
        <S.Tittle $textColor={textColor}>{t('uptime.100uptime')}</S.Tittle>
        <Switcher
          size={ESwitcherSize.m}
          checked={uptime}
          onChange={handleChange}
          disabled={isLoading || cooldown}
          disabledBackground={EColors.Blue2NewBrand}
        />
        <S.Tittle $textColor={textColor}>{t('uptime.65uptime')}</S.Tittle>
      </S.SwitcherWrapper>
    ),
    [EUptimeSwitcherVariants.PaymentSuccessScreen]: () => (
      <S.SwitcherWrapperSmall $wrapperBackground={wrapperBackground}>
        <S.TittleSmall $textColor={textColor}>{t('uptime.100uptime')}</S.TittleSmall>
        <Switcher
          size={ESwitcherSize.m}
          checked={uptime}
          onChange={handleChange}
          disabled={isLoading || cooldown}
          disabledBackground={EColors.Gray3}
        />
        <S.TittleSmall $textColor={textColor}>{t('uptime.65uptime')}</S.TittleSmall>
      </S.SwitcherWrapperSmall>
    ),
  };

  return uptimeSwitcherComponent[variant]();
};
