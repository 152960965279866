import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useInnerWidth } from 'hooks/useInnerWidth';
import { selectCart } from 'store/cart-reducer/cart-selectors';
import { selectInvoiceStatus } from 'store/invoice-reducer/invoice.selectors';
import { fetchInvoiceThunkAction } from 'store/invoice-reducer/invoice.thunk-actions';
import { requestProfileDataThunkAction } from 'store/profile-reducer/profile.thunk-actions';
import { EPromoCodeStatus } from 'store/promocode-reducer/constants/promocode-statuses';
import { setPromoStatusAction } from 'store/promocode-reducer/promocode.reducer';
import { selectActiveMiners } from 'store/purchase-reducer/purchase.selectors';
import { useAppDispatch } from 'store/store';
import { BreakPoint } from 'styles/style-variables/breakpoint';

import { gtmClickCartCheckout } from 'utils/gtmSender/gtmSender';
import { AppRoute } from 'utils/route/app-route';

import { CartContent } from './components/logic/CartContent';
import { CartTittle } from './components/ui/CartTittle';

import * as S from './Cart.styled';

export const Cart: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const CartFetchStatus = useSelector(selectInvoiceStatus);
  const minersData = useSelector(selectActiveMiners);
  const cartData = useSelector(selectCart);
  const { innerWidth } = useInnerWidth();
  const [sideSpace, setSideSpace] = useState((innerWidth - parseInt(BreakPoint.DesktopTop)) / 2);
  const { cartId, totalCostPrice, totalCosts, cartItems } = cartData;
  const isEmptyCart = cartItems.length === 0;

  const gtmItems = minersData.map((minerData) => {
    const cartItem = cartItems.find((item) => item.productId === minerData.id);
    return {
      item_name: minerData.model,
      item_id: minerData.id,
      price: minerData.price,
      item_brand: minerData.model.split(' ')[0],
      item_hash_rate: minerData.hashrate,
      item_electricity_fee: minerData.dailyElectricityFee,
      quantity: cartItem ? cartItem.quantity : 0,
    };
  });

  const allCartItemsQuantity = cartItems.reduce((acc, item) => acc + item.quantity, 0);
  const handleCheckoutClick = async () => {
    const itemsInCart = gtmItems.filter((el) => el.quantity);
    gtmClickCartCheckout(itemsInCart);
    await dispatch(requestProfileDataThunkAction());
    await dispatch(fetchInvoiceThunkAction(cartId));
    dispatch(setPromoStatusAction(EPromoCodeStatus.Button));
    navigate(AppRoute.PurchaseCheckout());
  };

  const isCheckoutPending = CartFetchStatus === 'pending';

  useEffect(() => {
    setSideSpace((innerWidth - parseInt(BreakPoint.DesktopTop)) / 2);
  }, [innerWidth]);

  return (
    <>
      <S.FixedCartBlock as="section" $isEmptyCart={isEmptyCart} $sideSpace={sideSpace}>
        <S.CartWrapper>
          <CartTittle t={t} />
          {isEmptyCart || !allCartItemsQuantity ? (
            <S.Text>{t('cart.addMinersText')}</S.Text>
          ) : (
            <CartContent
              cartId={cartId}
              handleCheckoutClick={handleCheckoutClick}
              cartList={cartItems}
              minersData={minersData}
              totalCartSum={totalCostPrice + totalCosts}
              isCheckoutPending={isCheckoutPending}
              t={t}
            />
          )}
        </S.CartWrapper>
      </S.FixedCartBlock>
    </>
  );
};
