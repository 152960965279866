import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { EColors } from 'styles/style-variables/colors';
import { FontFamily } from 'styles/style-variables/font-family';

import { SText } from 'components/styled/SText';

export const TableWrapper = styled.div`
  display: flex;
  flex: 1 0;
  flex-direction: column;
`;

export const Table = styled.table`
  font-family: ${FontFamily.Montserrat};
  font-style: normal;
  font-weight: 500;
  font-variant-numeric: lining-nums tabular-nums;

  & thead {

    & th {
      padding-right: 16px;
      padding-bottom: 24px;
      font-size: 10px;
      line-height: 14px;
      letter-spacing: 0.3px;
      text-transform: uppercase;
      color: ${EColors.Gray2};
      vertical-align: bottom;
    }
  }

  & td {
    padding-right: 16px;
    padding-bottom: 24px;
    font-size: 12px;
    line-height: 16px;
    color: ${EColors.Gray1Text};
  }

  & td,
  th {
    text-align: start;
    vertical-align: top;

    &:nth-of-type(1),
    &:nth-of-type(2),
    &:nth-of-type(4) {
      min-width: 150px;
    }

    &:nth-of-type(3) {
      width: 100%;
    }
  }

  @media (max-width: ${BreakPoint.TabletTop}) {

    & td,
    th {

      &:nth-of-type(1),
      &:nth-of-type(2),
      &:nth-of-type(4) {
        min-width: 100px;
      }
    }
  }
`;

export const HeadCell = styled.div`
  display: flex;
  align-items: center;
`;

export const ValueCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
