import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { PaymentSection } from 'pages/PagePurchase/components/logic/PaymentSection/PaymentSection';
import { SContainer, SInvoiceWrapper } from 'pages/PagePurchase/components/styled';
import { InvoiceDescription } from 'pages/PagePurchase/components/ui/InvoiceDescription';
import { InvoiceHeader } from 'pages/PagePurchase/components/ui/InvoiceHeader';
import { EPurchaseStep } from 'store/api/cuverse-api/purchase/types/purchase-step.type';
import { selectCart } from 'store/cart-reducer/cart-selectors';
import { selectInvoiceData } from 'store/invoice-reducer/invoice.selectors';

import { SVisuallyHidden } from 'components/styled/SVisuallyHidden';
import { AppRoute } from 'utils/route/app-route';

export const InvoiceScreen: React.FC = () => {
  const { t } = useTranslation();
  const invoiceData = useSelector(selectInvoiceData);
  const cartData = useSelector(selectCart);

  const { cartId, cartItems } = cartData;

  return (
    <>
      {!cartId || !cartData.cartItems.length ? (
        <Navigate to={AppRoute.Purchase()} replace />
      ) : (
        <>
          <InvoiceHeader
            step={EPurchaseStep.Checkout}
            invoiceNumber={invoiceData?.id}
            invoiceDate={invoiceData?.createdAt}
            t={t}
          />
          <SContainer>
            <SInvoiceWrapper as="section">
              <SVisuallyHidden as="h2">Invoice data</SVisuallyHidden>
              <InvoiceDescription cartItems={cartItems} />
              <PaymentSection
                t={t}
                purchaseStep={EPurchaseStep.Checkout}
                invoiceData={invoiceData}
              />
            </SInvoiceWrapper>
          </SContainer>
        </>
      )}
    </>
  );
};
