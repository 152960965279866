import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { EColors } from 'styles/style-variables/colors';
import { FontFamily } from 'styles/style-variables/font-family';

import { SText } from 'components/styled/SText';

export const OrdersList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 16px;
  font-family: ${FontFamily.Montserrat};
  font-style: normal;
  font-weight: 500;
  font-variant-numeric: lining-nums tabular-nums;
`;

export const OrderListItem = styled.li`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  padding: 12px;
  border-radius: 12px;
  background: ${EColors.White};
`;

export const Row = styled.div`
  display: flex;
  gap: 12px;
`;

export const Description = styled.div`
  min-width: 100px;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color: ${EColors.Gray2};
`;

export const Values = styled.div`
  display: flex;
  font-size: 12px;
  line-height: 16px;
  color: ${EColors.Gray1Text};
`;

export const Tittle = styled.div`
  display: flex;
  align-items: center;
`;

export const Value = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const DownloadLink = styled.a`
  display: flex;
  align-items: center;
  gap: 4px;
  text-decoration: none;
`;

export const DownloadTextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-items: end;
`;

export const InvoiceNumber = styled(SText)`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const Text = styled(SText)`
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color: ${EColors.Blue2NewBrand};
`;

export const TextDark = styled(Text)`
  color: ${EColors.Gray1Text};
`;
