import type { FC } from 'react';

import { formatBtcOutput } from 'utils/formatters/format-btc-output.util';
import { formatUsdOutput } from 'utils/formatters/format-usd-output.util';

import * as S from './TotalAmount.styled';

interface IPropsTotalAmount {
  sumUsd: number;
  sumBtc?: number;
  withBtc?: boolean;
  t: (key: string) => string;
}

export const TotalAmount: FC<IPropsTotalAmount> = ({
  t,
  sumUsd,
  sumBtc,
  withBtc = false,
}): JSX.Element => {
  return (
    <S.TotalAmountWrapper>
      <S.Title>{t('purchase.totalText')}</S.Title>
      <S.ValuesWrapper>
        <S.Value>{`$${formatUsdOutput(sumUsd)}`}</S.Value>
        {withBtc && sumBtc && <S.BtcValue>{`${formatBtcOutput(sumBtc)} BTC`}</S.BtcValue>}
      </S.ValuesWrapper>
    </S.TotalAmountWrapper>
  );
};
