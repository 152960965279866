import { FC, useRef } from 'react';

import { ITabsSliderProps } from './types/tabs-slider-props.interface';

import * as S from './TabsSlider.styled';

export const TabsSlider: FC<ITabsSliderProps> = ({ items, activeTabValue, handleTabClick }) => {
  const startTouchX = useRef(0);
  const endTouchX = useRef(0);
  const trackRef = useRef<HTMLDivElement | null>(null);

  const handleTouchStart = (e: React.TouchEvent) => {
    startTouchX.current = e.touches[0].clientX;
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    endTouchX.current = e.touches[0].clientX;
    if (trackRef.current) {
      const moveDistance = endTouchX.current - startTouchX.current;
      trackRef.current.style.transform = `translateX(${moveDistance}px)`;
    }
  };

  const handleTouchEnd = () => {
    if (trackRef.current) {
      trackRef.current.style.transition = 'transform 0.3s ease';
      trackRef.current.style.transform = 'translateX(0)';
    }
  };

  return (
    <S.SliderContainer
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <S.SliderTrack ref={trackRef}>
        {items.map((item) => (
          <S.SliderItem key={item.value}>
            <S.TabButton
              type="button"
              variant="alter-primary"
              icon={item.icon && item.icon}
              text={item.text}
              onClick={() => handleTabClick && handleTabClick(item.value)}
              disabled={item.value === activeTabValue}
            />
          </S.SliderItem>
        ))}
      </S.SliderTrack>
    </S.SliderContainer>
  );
};
