import { type FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as MinerWhiteIcon } from 'assets/icons/button-icons/miner-white.svg';
import { useInnerWidth } from 'hooks/useInnerWidth';
import { OrdersHistoryDesktop } from 'pages/PageProfile/components/ui/OrdersHistoryDesktop/OrdersHistoryDesktop';
import { OrdersHistoryMobile } from 'pages/PageProfile/components/ui/OrdersHistoryMobile';
import {
  selectOrdersHistory,
  selectOrdersHistoryStatus,
} from 'store/profile-reducer/profile.selectors';
import { requestOrdersHistoryThunkAction } from 'store/profile-reducer/profile.thunk-actions';
import { useAppDispatch } from 'store/store';
import { BreakPoint } from 'styles/style-variables/breakpoint';

import { SPageSection } from 'components/styled/SPageSection';
import { SSpinnerBlue } from 'components/styled/SSpinnerBlue';
import { ContentPlaceholder } from 'components/ui/ContentPlaceholder';
import { Pagination } from 'components/ui/Pagination';
import { AppRoute } from 'utils/route/app-route';

import { ORDERS_PER_PAGE } from './constants/orders-per-page';
import { TOP_MARGIN } from './constants/top-margin';

import * as S from './OrdersHistorySection.styled';

export const OrdersHistorySection: FC = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { innerWidth } = useInnerWidth();
  const ordersHistory = useSelector(selectOrdersHistory);
  const ordersHistoryStatus = useSelector(selectOrdersHistoryStatus);

  const [currentPage, setCurrentPage] = useState(1);
  const isDesktop = innerWidth >= parseInt(BreakPoint.TabletLow);
  const isPending = ordersHistoryStatus === 'pending';
  const totalPages = Math.ceil(
    ordersHistory?.length / ORDERS_PER_PAGE[isDesktop ? 'DESKTOP' : 'MOBILE'],
  );

  const handleNewPage = (newPage: number): void => {
    if (newPage <= 0) {
      setCurrentPage(1);
    } else if (newPage > totalPages) {
      setCurrentPage(totalPages);
    } else {
      setCurrentPage(newPage);
    }
  };

  const slicedItems = [...ordersHistory]
    ?.reverse()
    ?.slice(
      (currentPage - 1) * ORDERS_PER_PAGE[isDesktop ? 'DESKTOP' : 'MOBILE'],
      currentPage * ORDERS_PER_PAGE[isDesktop ? 'DESKTOP' : 'MOBILE'],
    );
  useEffect(() => {
    void dispatch(requestOrdersHistoryThunkAction());
  }, [dispatch]);

  useEffect(() => {
    if (!isDesktop) {
      window.scrollTo({ top: TOP_MARGIN, behavior: 'smooth' });
    }
  }, [currentPage, isDesktop]);

  if (isPending) {
    return (
      <SPageSection tittleCeo="orders history">
        <S.StyledOrdersHistoryWrapper $isDesktop={true}>
          <SSpinnerBlue />
        </S.StyledOrdersHistoryWrapper>
      </SPageSection>
    );
  }

  if (!ordersHistory || ordersHistory.length === 0)
    return (
      <SPageSection tittleCeo="orders history">
        <S.StyledOrdersHistoryWrapper $isDesktop={true}>
          <ContentPlaceholder
            buttonIcon={<MinerWhiteIcon />}
            buttonText={t('contentPlaceholder.buyMiner')}
            tittleText={t('contentPlaceholder.buyYourFirstMiner')}
            onButtonClick={() => navigate(AppRoute.Purchase())}
          />
        </S.StyledOrdersHistoryWrapper>
      </SPageSection>
    );

  return (
    <SPageSection tittleCeo="orders history">
      <S.StyledOrdersHistoryWrapper $isDesktop={isDesktop}>
        {isDesktop ? (
          <OrdersHistoryDesktop t={t} ordersHistory={slicedItems} />
        ) : (
          <OrdersHistoryMobile t={t} ordersHistory={slicedItems} />
        )}
      </S.StyledOrdersHistoryWrapper>
      {totalPages > 1 && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          handleSetCurrentPage={handleNewPage}
          t={t}
        />
      )}
    </SPageSection>
  );
};
