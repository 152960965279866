import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';

export const SInvoiceWrapper = styled.section`
  display: flex;
  flex-direction: row;
  gap: 25px;
  padding: 24px 32px 64px 24px;
  background: linear-gradient(#0e46c0 0 212px, #f4f4f7 212px 100px);

  @media (min-width: ${BreakPoint.LaptopLow}) and (max-width: ${BreakPoint.LaptopTop}) {
    gap: 24px;
    padding: 24px 24px 64px;
  }

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    flex-direction: column;
    gap: 24px;
    padding: 24px 16px 64px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    flex-direction: column;
    gap: 16px;
    padding: 24px 16px 64px;
    background: linear-gradient(#0e46c0 0 66px, #f4f4f7 66px 100px);
  }
`;
